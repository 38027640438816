import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { Router, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


//service
import OutputService from "../services/OutputService";
import TableDataService from "../services/TableService";
import DeleteIcon from '@mui/icons-material/Delete';

// component
// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';


// Material Table
import { DataGrid } from '@mui/x-data-grid';
import OutputsService from "../services/OutputService";
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AutorenewIcon from '@mui/icons-material/Autorenew';


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-headerName"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Output = (props) => {

    let navigate = useNavigate();

    const initialOutputState = {
        data_documento: null,
        data_pagamento: null,
        fattura_numero: "",
        importo: 0.0,
        metodo: "",
        numero_registro: "",
        area_competenza: "",
        causale: "",
        campagna: "",
        descrizione: "",
        contatto_id: props.sh.id,
        nome: props.sh.nome,
        cognome: props.sh.cognome,
        ragione_sociale: props.sh.ragione_sociale
    }
    // column Data Grid
    const columns = [
        { field: 'data_documento', width: 150, headerName: "Data documento", type: "date" },
        { field: 'data_pagamento', width: 150, headerName: "Data pagamento", type: "date" },
        { field: 'fattura_numero', width: 150, headerName: "Numero fattura" },
        { field: 'importo', width: 150, headerName: "Importo" },
        { field: 'metodo', width: 150, headerName: "Metodo", },
        { field: 'numero_registro', width: 150, headerName: "Numero registro", },
        { field: 'area_competenza', width: 150, headerName: "Area competenza", },
        { field: 'causale', width: 150, headerName: "Causale", },
        { field: 'campagna', width: 150, headerName: "Campagna", },
        { field: 'descrizione', width: 150, headerName: "Descrizione", },
    ];

    const [Output, setOutput] = useState(initialOutputState);
    const [Outputs, setOutputs] = useState([]);

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };


    useEffect(() => {
        retrieveOutputs();
        retrieveTables();

    }, []);

    //start Dialog

    const [open, setOpen] = React.useState(false);
    const [newOutput, setnewOutput] = useState(false);


    const handleClickOpen = (row) => {
        setOpen(true);
        setnewOutput(false);
        setOutput(row.row);
    };

    const handleNewOutput = () => {
        setOpen(true);
        setnewOutput(true);
        setOutput(initialOutputState);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialog = (e) => {
        setOpenDialog(true)

    }

    //end Dialog

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOutput({ ...Output, [name]: (name == "data_pagamento" || name == "data_documento") ? new Date(value) : value });
    };

    const updateOutput = () => {
        OutputService.update(Output.id_out, Output)
            .then(response => {
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };


    // new output
    const saveOutput = () => {

        OutputService.create(Output)
            .then(response => {
                setOutput({
                    id_out: response.data.id_out,
                    data_documento: new Date(response.data.data_documento),
                    data_pagamento: new Date(response.data.data_pagamento),
                    fattura_numero: response.data.fattura_numero,
                    importo: response.data.importo,
                    metodo: response.data.metodo,
                    numero_registro: response.data.numero_registro,
                    area_competenza: response.data.area_competenza,
                    causale: response.data.causale,
                    campagna: response.data.campagna,
                    descrizione: response.data.descrizione,
                    contatto_id: response.data.contatto_id
                });
            })
            .catch(e => {
                console.log(e);
            });
        handleClose();
    };
    // end output

    const deleteOutput = (id) => {
        OutputService.remove(id)
            .then(response => {
                alert("Uscita cancellata")
            })
            .catch(e => {
                console.log(e);
            });
        handleClose();
    };

    const retrieveOutputs = () => {
        OutputsService.get(Output.contatto_id)
            .then(response => {
                setOutputs(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-headerName"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-headerName">
                    {newOutput ? "Aggiungi uscita" : "Modifica uscita"}
                </DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <TextField id="outlined-basic" label="Data documento" name="data_documento" InputLabelProps={{ shrink: true }} type="date" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.data_documento?.toISOString().split('T')[0]} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Data pagamento" name="data_pagamento" variant="outlined" InputLabelProps={{ shrink: true }} type="date" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.data_pagamento?.toISOString().split('T')[0]} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Numero fattura" name="fattura_numero" variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={Output.fattura_numero} onChange={handleInputChange} />
                    <TextField id="outlined-basic" InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                        type="number" label="Importo" name="importo" variant="outlined" style={{ marginBottom: "20px", marginRight: "20px", width: "15ch" }} value={Output.importo} onChange={handleInputChange} />

                    <FormControl sx={{ minWidth: "25ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Metodo di pagamento</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(Output.metodo) ? Output.metodo : ""}
                            autowidth
                            name="metodo"
                            onChange={handleInputChange}
                            label="Metodo di pagamento"
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 1) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Numero registro" name="numero_registro" variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={Output.numero_registro} onChange={handleInputChange} />
                    <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Area competenza</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(Output.area_competenza) ? Output.area_competenza : ""}
                            autowidth
                            name="area_competenza"
                            onChange={handleInputChange}
                            label="Area competenza"
                        >
                            <MenuItem value={"Att.Istituzionali "}>Att. Istituzionali</MenuItem>
                            <MenuItem value={"Oneri"}>Oneri</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Causale</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(Output.causale) ? Output.causale : ""}
                            autowidth
                            name="causale"
                            onChange={handleInputChange}
                            label="Causale"
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 5) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>



                    <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(Output.campagna) ? Output.campagna : ""}
                            autowidth
                            name="campagna"
                            onChange={handleInputChange}
                            label="Campagna"
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 3) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>
                    <br /><TextField id="outlined-multiline-static" fullWidth label="Descrizione" name="descrizione" multiline rows={3} variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={Output.descrizione} onChange={handleInputChange} />
                    <DialogContentText>
                        Per salvare clicca su {newOutput ? "Salva" : "Aggiorna"} altrimenti Chiudi
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<DeleteIcon />} color="error" onClick={() => deleteOutput(Output.id_out)}>Cancella</Button>
                    <Button onClick={handleClose}>
                        Chiudi
                    </Button>
                    <Button onClick={newOutput ? saveOutput : updateOutput}>{newOutput ? "Salva" : "Aggiorna"}</Button>
                </DialogActions>
            </Dialog>
            <Button variant="contained" sx={{ marginBottom: "20px" }} startIcon={<AddIcon />} onClick={handleNewOutput} >Aggiungi uscita</Button >
            <Button variant="contained" sx={{ marginBottom: "20px", float: "right" }} endIcon={<AutorenewIcon />} onClick={retrieveOutputs}>
                Aggiorna
            </Button>
            <DataGrid
                rows={Outputs.map((row) =>
                ({
                    ...row,
                    data_documento: new Date(row.data_documento),
                    data_pagamento: new Date(row.data_pagamento)
                }))}
                sx={{ height: "600px", marginBottom: "50px" }}
                columns={columns}
                pageSize={15}
                getRowId={(row) => row.id_out}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'data_pagamento', sort: 'desc' }],
                    },
                }}
                rowsPerPageOptions={[15]}
                onRowClick={(e) =>
                    handleClickOpen(e)}
            />
        </>
    );
};

export default Output;
