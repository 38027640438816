import axios from "axios";


// deploy http://981e7478-2274-4910-a841-2e6e38cfd341.pub.instances.scw.cloud/api

const API_URL = "http://981e7478-2274-4910-a841-2e6e38cfd341.pub.instances.scw.cloud/api";
// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL + "auth/",
// });

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/auth/signin", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, nome, cognome) {
    console.log(username, cognome);
    return axios.post(API_URL + "/auth/signup", {
      username,
      email,
      password,
      nome,
      cognome
    });
  }
}

export default new AuthService();
