import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';

const columns = [
    { field: 'giorno', headerName: 'Giorno', width: 100, type: "date" },
    {
        field: 'tot_ore', headerName: 'Totale ore', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'media_ore', headerName: 'Media ore', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
];


const totOre = (data) => {

    var time3 = "00";

    data?.map((row) => {

        var newTime1 = 0;
        var newTime2 = 0;

        var time1 = row.orario_inizio?.split(':')
        var time2 = row.orario_fine?.split(':')

        newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
        newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

        time3 = time3 + newTime2 - newTime1;
    }
    )
    var m = time3 % 60;
    var h = (time3 - m) / 60;
    return (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m)
}

const avgTime = (data) => {

    var time3 = "00";
    var len = data?.length;

    data?.map((row) => {

        var newTime1 = 0;
        var newTime2 = 0;

        var time1 = row.orario_inizio?.split(':')
        var time2 = row.orario_fine?.split(':')

        newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
        newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

        time3 = time3 + newTime2 - newTime1;
    }
    )
    var time4 = Math.ceil(time3 / len);
    var m = time4 % 60;
    var h = (time4 - m) / 60;
    return !isNaN(m) ? (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m) : "00:00"
}

const buildRows = (inizio, fine, data) => {

    var days = [];
    var day = new Date(inizio);
    day = new Date(day.setDate(day.getDate() - parseInt(1))).setHours(0, 0, 0, 0);
    var nextDay = new Date(day);

    for (var i = 0; nextDay < new Date(fine); i++) {
        days[i] = {
            id: i,
            giorno: new Date(nextDay.setDate(nextDay.getDate() + parseInt(1))),
            tot_ore: totOre(data.filter(fil => (new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime())),
            media_ore: avgTime(data.filter(fil => (new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()))
        };
    }
    return days;
}

const sumDays = (rows) => {
    var sum = 0;

    rows.map(row =>
        sum += parseInt(row.tot_ore.split(":")[0]) * 60 + parseInt(row.tot_ore.split(":")[1])
    )
    var m = sum % 60;
    var h = (sum - m) / 60;
    return (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m)
}


const avgHours = (rows) => {

    var sum = 0;
    var len = rows?.filter(fil => fil.tot_ore != "00:00").length;

    rows.map(row =>
        sum += parseInt(row.media_ore.split(":")[0]) * 60 + parseInt(row.media_ore.split(":")[1])
    )

    var avg = Math.ceil(sum / len);
    var m = avg % 60;
    var h = (avg - m) / 60;
    return !isNaN(m) ? (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m) : "00:00"
}

export default function DataTable(props) {

    const rows = buildRows(props.query.data_inizio, props.query.data_fine, props.rows);

    return (
        <>
            <Paper>
                <Box
                    sx={{
                        height: 600,
                        width: '100%',
                        '& .super-app.total': {
                            backgroundColor: '#1976d214',
                            fontWeight: '600',
                        }
                    }}
                >
                    <DataGrid
                        rows={[...rows,
                        { id: 'totale', label: 'TOTALE', tot_ore: sumDays(rows), media_ore: avgHours(rows) }]}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        getRowId={(row) => row.id}
                        pageSizeOptions={[5, 10]}
                        slots={{ toolbar: GridToolbar }}
                    />
                </Box>
            </Paper>
        </>
    );
}