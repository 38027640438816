import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { Router, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';


//service
import TableDataService from "../services/TableService";
import DonationDataService from "../services/DonationService"

// component
import Grid from '@mui/material/Grid'; // Grid version 1
import Container from '@mui/material/Container';


import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const TablesList = () => {
    const [tables, setTables] = useState([]);
    const [table, setTable] = useState();


    const saveTable = () => {

        TableDataService.create(table)
            .then(response => {
                setTable({
                    riferimento: response.table.riferimento,
                    campi: response.table.campi,
                });
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [list, setList] = useState([]);

    const retriveTableRif = index => {
        TableDataService.get(index)
            .then(response => {
                setList(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }

    useEffect(() => {
        retrieveTables();
    }, []);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [index, setIndex] = useState(0);

    const handlePanel = (event) => {
        const { name, value } = event.target;
        setIndex(value);
        setList(tables.filter(tab => tab.riferimento == value))
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTable({
            riferimento: index,
            campi: value,
        });
    };

    const deleteField = (id) => {
        TableDataService.remove(id)
            .then(response => {
                alert("Campo cancellato")
            })
            .catch(e => {
                console.log(e);
            });
    };


    // const table
    const columns = [
        {
            field: 'action', headerName: '',
            sortable: false,
            renderCell: (params) => {

                return <IconButton color="error" component="label" onClick={() => deleteField(params.id)}>
                    <DeleteIcon />
                </IconButton>
            }
        },
        { field: 'campi', headerName: 'Campo', width: 230 }];


    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={8} md={8} >
                <Grid sx={{ margin: "20px" }}>
                    <Paper>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 2, width: '25ch' },
                                '& .MuiFormControl-root': { m: 2, width: '25ch' },
                                '& .MuiButton-root': { m: 2, float: "right" },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Seleziona il menu</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Seleziona il menu"
                                    name="menu"
                                    onChange={handlePanel}
                                >
                                    <MenuItem value={1}>Metodo</MenuItem>
                                    <MenuItem value={2}>Tipologia donazioni</MenuItem>
                                    <MenuItem value={3}>Campagna</MenuItem>
                                    <MenuItem value={4}>Progetto</MenuItem>
                                    <MenuItem value={5}>Causale</MenuItem>
                                    <MenuItem value={6}>Area competenza</MenuItem>
                                    <MenuItem value={7}>Categoria azione</MenuItem>
                                    <MenuItem value={12}>Categoria stakeholders</MenuItem>
                                    <MenuItem value={9}>Titolo</MenuItem>
                                    <MenuItem value={10}>Categoria volontario</MenuItem>
                                    <MenuItem value={11}>Fonte</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id="outlined-basic" label="Aggiungi campo" variant="outlined" name="campi" onChange={handleInputChange} />
                            <Button variant="contained" startIcon={<AddIcon />} onClick={saveTable}>Aggiungi</Button >
                            <Button variant="outlined" sx={{ float: "right" }} onClick={() => retriveTableRif(index)} endIcon={<AutorenewIcon />}>
                                Aggiorna
                            </Button>
                        </Box>
                        <div style={{ height: 600, margin: "20px" }}>
                            <DataGrid
                                rows={list}
                                columns={columns}
                                getRowId={(row) => row.id}
                                rowsPerPageOptions={[]}
                                disableColumnSelector
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'data', sort: 'desc' }],
                                    },
                                }}
                            />
                        </div>
                    </Paper >
                </Grid>
            </Grid>
        </Container >

    );
};

export default TablesList;
