import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { ExportToExcel } from '../ExportToExcel'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';


//table
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

// service
import TableDataService from "../services/TableService";
import OutputsService from "../services/OutputService";

const Output = () => {

    const initialQuery = {
        data_inizio: null,
        data_fine: null,
        intervallo: null,
        importo: null,
        metodo: null,
        area_competenza: null,
        causale: null,
        campagna: null,
        set: false,
        nome: null,
        cognome: null,
        ragione_sociale: null
    }
    const [query, setQuery] = useState(initialQuery)

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const navigate = useNavigate();

    const [outputs, setOutputs] = useState([]);

    const retriveOutputs = () => {
        OutputsService.getAll()
            .then(response => {
                setOutputs(response.data);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const rows = (!query.set) ? outputs :
        outputs.filter(out =>
            ((query.data_inizio && query.data_inizio != "") ?
                (new Date(query.data_inizio) <= (new Date(out.data_pagamento))) : true)
            && ((query.data_fine && query.data_fine != "") ?
                ((new Date(out.data_pagamento)) <= (new Date(query.data_fine))) : true)
            && ((query.metodo) ? query.metodo == out.metodo : true) && ((query.area_competenza) ? query.area_competenza == out.area_competenza : true) &&
            ((query.causale) ? query.causale == out.causale : true) && ((query.campagna) ? query.campagna == out.campagna : true)
        )

    useEffect(() => {
        retriveOutputs();
        retrieveTables();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setQuery({ ...query, [name]: value, set: true });
    };

    const handleClickDate = (event) => {
        let offset = event.target.value;
        let now = Date.now();
        let setDate = new Date(now - 1000 * 60 * 60 * 24 * offset).toISOString().split('T')[0];
        console.log(setDate);
        console.log(new Date(now).toISOString().split('T')[0]);
        setQuery({ ...query, intervallo: offset, data_inizio: setDate, data_fine: new Date(now).toISOString().split('T')[0], set: true })
    }

    // column Data Grid
    const columns = [
        { field: 'nome', width: 150, headerName: "Nome" },
        { field: 'cognome', width: 150, headerName: "Cognome" },
        { field: 'ragione_sociale', width: 150, headerName: "Ragione sociale" },
        { field: 'data_documento', width: 150, headerName: "Data documento", type: 'date' },
        { field: 'data_pagamento', width: 150, headerName: "Data pagamento", type: 'date' },
        { field: 'fattura_numero', width: 150, headerName: "Numero fattura" },
        { field: 'importo', width: 150, headerName: "Importo" },
        { field: 'metodo', width: 150, headerName: "Metodo", },
        { field: 'numero_registro', width: 150, headerName: "Numero registro", },
        { field: 'area_competenza', width: 150, headerName: "Area competenza", },
        { field: 'causale', width: 150, headerName: "Causale", },
        { field: 'campagna', width: 150, headerName: "Campagna", },
        { field: 'descrizione', width: 150, headerName: "Descrizione", },
    ];

    return (
        <>
            <Grid xs={12} md={12} sx={{ marginBottom: "20px" }} >
                <Paper >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 2, width: '25ch' },
                            '& .MuiFormControl-root': { m: 2, width: '25ch' },
                            '& .MuiButton-root': { m: 2, float: "right" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Da" name="data_inizio" onChange={handleInputChange} value={(!query.data_inizio) ? new Date(null) : query.data_inizio} InputLabelProps={{ shrink: true }} type="date" variant="outlined" />
                        <TextField id="outlined-basic" label="A" name="data_fine" onChange={handleInputChange} InputLabelProps={{ shrink: true }} type="date" variant="outlined" value={(!query.data_fine) ? new Date(null) : query.data_fine} />
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Intervallo giorni</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.intervallo) ? query.intervallo : ""}
                                autowidth
                                onChange={handleClickDate}
                                label="Intervallo giorni"
                            >
                                <MenuItem value={null}>Seleziona</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={90}>90</MenuItem>
                            </Select>
                        </FormControl><br />
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Metodo di pagamento</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.metodo) ? query.metodo : ""}
                                autowidth
                                name="metodo"
                                onChange={handleInputChange}
                                label="Metodo di pagamento"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 1) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Area competenza</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.area_competenza) ? query.area_competenza : ""}
                                autowidth
                                name="area_competenza"
                                onChange={handleInputChange}
                                label="Area competenza"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                <MenuItem value={"Att.Istituzionali "}>Att. Istituzionali</MenuItem>
                                <MenuItem value={"Oneri"}>Oneri</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Causale</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.causale) ? query.causale : ""}
                                autowidth
                                name="causale"
                                onChange={handleInputChange}
                                label="Causale"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 5) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.campagna) ? query.campagna : ""}
                                autowidth
                                name="campagna"
                                onChange={handleInputChange}
                                label="Campagna"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 3) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Button variant="contained" startIcon={<RestartAltIcon />} onClick={() => setQuery(initialQuery)}>Reset</Button >
                    </Box>
                </Paper>
            </Grid >
            <Paper>
                <Grid xs={12} md={12} sx={{ textAlign: "end" }} >
                    <ExportToExcel apiData={rows} fileName={"Uscite"} />
                    <div style={{ height: "auto", width: '100%', marginBottom: "150px" }}>
                        <DataGrid
                            rows={
                                rows.map((row) =>
                                ({
                                    ...row,
                                    data_documento: new Date(row.data_documento),
                                    data_pagamento: new Date(row.data_pagamento)
                                }))
                            }
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id_out}
                            sx={{ height: "600px", margin: "10px" }}
                            rowsPerPageOptions={[5]}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'data_pagamento', sort: 'desc' }],
                                },
                            }}
                            onRowDoubleClick={(e) =>
                                navigate("/dashboard/stakeholders/" + e.row.contatto_id)}
                        />
                    </div >
                </Grid>
            </Paper>
        </>
    );
};

export default Output;