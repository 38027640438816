import React, { Component } from "react";
import { connect } from "react-redux";
import { Routes, BrowserRouter, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login.component";
import Dashboard from "./components/Dashboard";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';

// components
import ContactsList from './components/ContactsList';
import Contact from './components/Contact';
import AddContact from './components/AddContact';
import OutputsList from "./components/OutputsList";
import Chart from "./components/Chart"
import TablesList from "./components/Table";
import StakeholdersList from "./components/StakeholdersList";
import Stakeholder from "./components/Stakeholder";
import Report from "./components/Report";
import Donations from "./components/Donations";
import Output from "./components/Output";

class App extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);

        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        };

        history.listen((location) => {
            props.dispatch(clearMessage()); // clear message when changing location
        });
    }

    componentDidMount() {
        const user = this.props.user;

        if (user) {
            this.setState({
                currentUser: user,
                showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                showAdminBoard: user.roles.includes("ROLE_ADMIN"),
            });
        }
    }

    logOut() {
        this.props.dispatch(logout());
    }

    render() {
        const { currentUser } = this.state;

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login history={history} />} />
                    <Route path="/dashboard/" element={(this.state.currentUser) ? <Dashboard user={this.state.currentUser} logOut={this.logOut} /> : null}>
                        <Route path="stakeholders" element={<StakeholdersList />} />
                        <Route path="stakeholders/add" element={<Stakeholder />} />
                        <Route path="donazioni" element={<Donations />} />
                        <Route path="uscite" element={<Output />} />
                        <Route path="tables" element={<TablesList />} />
                        <Route path="report" element={<Report />} />
                        <Route path="outputs" element={<OutputsList />} />
                        <Route path="stakeholders/:id" element={<Stakeholder />} />
                        <Route path="stakeholders/:id/:edit" element={<Stakeholder v={true} />} />
                        <Route path="stakeholders/:id/:azione" element={<Stakeholder panel={4} />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(App);
