import http from "../http-common";

const getAll = () => {
    return http.get("/tables");
};

const get = (rif) => {
    return http.get(`/tables/${rif}`);
};


const create = data => {
    return http.post("/tables", data);
};

const update = (id, data) => {
    return http.put(`/tables/${id}`, data);
};

const remove = id => {
    return http.delete(`/tables/${id}`);
};

const removeAll = () => {
    return http.delete(`/tables`);
};

const findByTitle = title => {
    return http.get(`/tables?title=${title}`);
};

const TableService = {
    getAll,
    get,
    create,
    update,
    remove,
    removeAll,
    findByTitle
};

export default TableService;
