import axios from "axios";

// deploy http://981e7478-2274-4910-a841-2e6e38cfd341.pub.instances.scw.cloud/api
// local http://localhost:8080/api

export default axios.create({
  baseURL: "http://981e7478-2274-4910-a841-2e6e38cfd341.pub.instances.scw.cloud/api",
  headers: {
    "Content-type": "application/json"
  }
});
