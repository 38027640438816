import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';


//table
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';

//time
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import AutorenewIcon from '@mui/icons-material/Autorenew';

//menuitem
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//time
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';

// input
import TextField from '@mui/material/TextField';

// service
import ActionService from "../services/ActionService";
import TableService from "../services/TableService";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Actions = props => {

    const initialActionState = {
        data: null,
        orario_inizio: null,
        orario_fine: null,
        tipologia: "",
        note: "",
        contatto_id: props.stakeholder.id,
        nome: props.stakeholder.nome,
        cognome: props.stakeholder.cognome,
        categoria_sh: props.stakeholder.categoria
    };

    const [Action, setAction] = useState(initialActionState);
    const [Rows, setRows] = useState([])

    const getAllActions = () => {
        ActionService.get(props.stakeholder.id)
            .then(response => {
                setRows(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }


    useEffect(() => {
        getAllActions();
        retrieveTables();
    }, []);

    //new Action

    const saveAction = () => {

        const data = {
            id_act: Action.id_act,
            data: Action.data,
            orario_inizio: startTime.get("hour") + ":" + startTime.get("minute") + ":00",
            orario_fine: endTime.get("hour") + ":" + endTime.get("minute") + ":00",
            tipologia: Action.tipologia,
            note: Action.note,
            contatto_id: Action.contatto_id,
            nome: Action.nome,
            cognome: Action.cognome,
            categoria_sh: Action.categoria_sh
        }

        ActionService.create({
            ...data,
            categoria_sh: data.categoria_sh.join(', ')
        })
            .then(response => {
                setAction({
                    id_act: response.data.id_act,
                    data: new Date(response.data.data),
                    orario_inizio: response.data.orario_inizio,
                    orario_fine: response.data.orario_fine,
                    tipologia: response.data.tipologia,
                    note: response.data.note,
                    contatto_id: response.data.contatto_id,
                    nome: response.data.nome,
                    cognome: response.data.cognome,
                    categoria_sh: response.data.categoria_sh
                });
            })
            .catch(e => {
                console.log(e);
            });
        handleClose();
    };

    // const table
    const columns = [
        { field: 'data', headerName: 'Data', type: "date" },
        { field: 'orario_inizio', headerName: 'Ora inizio', type: 'number' },
        { field: 'orario_fine', headerName: 'Ora fine' },
        {
            field: 'tot_ore', headerName: 'Totale ore',
            cellClassName: (params) => {

                const row = params.row;

                var time3 = 0;

                var time1 = row.orario_inizio?.split(':')
                var time2 = row.orario_fine?.split(':')

                var newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
                var newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

                time3 = newTime2 - newTime1;

                var m = time3 % 60;
                var h = (time3 - m) / 60;

                return clsx('super-app', {
                    check: h == 0 || h >= 8,
                });
            }
        },
        { field: 'tipologia', headerName: 'Tipologia' },
        { field: 'note', headerName: 'Note' },
    ];

    //start Dialog

    const [open, setOpen] = React.useState(false);
    const [newAction, setnewAction] = useState(false);

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [startTime, setStartTime] = useState(dayjs());
    const [endTime, setEndTime] = useState(dayjs());

    const handleClickOpen = (e) => {
        setOpen(true);
        setnewAction(false);
        setAction({ ...e.row });
        setStartTime(dayjs().set("hour", e.row.orario_inizio?.split(":")[0]).set("minute", e.row.orario_inizio?.split(":")[1]));
        setEndTime(dayjs().set("hour", e.row.orario_fine?.split(":")[0]).set("minute", e.row.orario_fine?.split(":")[1]));
    };

    const handleNewAction = () => {
        setOpen(true);
        setnewAction(true);
        setAction(initialActionState);
        setStartTime(dayjs());
        setEndTime(dayjs());
    }

    const handleClose = () => {
        setOpen(false);
    }

    //end Dialog

    const info = (props.v) ? props.v : false;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAction({ ...Action, [name]: (name == "data") ? new Date(value) : value });    };

    const updateAction = () => {
        ActionService.update(Action.id_act,
            {
                ...Action,
                orario_inizio: startTime.get("hour") + ":" + startTime.get("minute") + ":00",
                orario_fine: endTime.get("hour") + ":" + endTime.get("minute") + ":00"
            })
            .then(response => {
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialog = (e) => {
        setOpenDialog(true)

    }

    const countHours = (row) => {
        var time3 = 0;

        var time1 = row.orario_inizio?.split(':')
        var time2 = row.orario_fine?.split(':')

        var newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
        var newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

        time3 = newTime2 - newTime1;

        var m = time3 % 60;
        var h = (time3 - m) / 60;
        return !isNaN(m) ? (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m) : "00:00"
    }


    const deleteAction = (id) => {
        ActionService.remove(id)
            .then(response => {
                alert("Azione cancellata")
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {newAction ? "Aggiungi azione" : "Modifica azione"}
                </DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <TextField id="outlined-basic" label="Data" name="data" InputLabelProps={{ shrink: true }} type="date" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Action.data?.toISOString().split('T')[0]} disabled={info} onChange={handleInputChange} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                            label="Orario inizio"
                            value={startTime}
                            name="orario_inizio"
                            onChange={(newValue) => setStartTime(newValue)}
                            style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}
                            format="HH:mm"
                        />
                        <TimeField
                            label="Orario fine"
                            value={endTime}
                            name="orario_fine"
                            onChange={(newValue) => setEndTime(newValue)}
                            style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}
                            format="HH:mm"
                        />
                    </LocalizationProvider>
                    <FormControl sx={{ minWidth: "25ch", marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(Action.tipologia) ? Action.tipologia : ""}
                            autowidth
                            name="tipologia"
                            onChange={handleInputChange}
                            label="Tipologia"
                            defaultOpen
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 7) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Note" name="note" fullWidth multiline rows={3} variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Action.note} disabled={info} onChange={handleInputChange} />                        <DialogContentText>
                        Per salvare clicca su {newAction ? "Salva" : "Aggiorna"} altrimenti Chiudi
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<DeleteIcon />} color="error" disabled={props.dis} onClick={() => deleteAction(Action.id_act)}>Cancella</Button>
                    <Button onClick={handleClose}>
                        Chiudi
                    </Button>
                    <Button onClick={newAction ? saveAction : updateAction}>{newAction ? "Salva" : "Aggiorna"}</Button>
                </DialogActions>
            </Dialog >
            <Button variant="contained" sx={{ marginBottom: "20px" }} startIcon={<AddIcon />} onClick={handleNewAction}>Aggiungi azione</Button >
            <Button variant="contained" sx={{ marginBottom: "20px", float: "right" }} onClick={getAllActions} endIcon={<AutorenewIcon />}>
                Aggiorna
            </Button>
            <Box
                sx={{
                    height: 600,
                    width: '100%',
                    margin: "10px",
                    '& .super-app.check': {
                        backgroundColor: ' #e0b73c8c',
                        fontWeight: '600',
                    },
                }}
            >
                <DataGrid
                    rows={Rows.map((row) =>
                    ({
                        ...row,
                        data: new Date(row.data),
                        tot_ore: countHours(row)
                    }))}
                    columns={columns}
                    getRowId={(row) => row.id_act}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'data', sort: 'desc' }],
                        },
                    }}
                    onRowClick={(e) =>
                        handleClickOpen(e)}
                />
            </Box>
        </>
    );
};

export default Actions;