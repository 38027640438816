import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { Router, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


//service
import StakeholderService from "../services/StakeholderService";

// component


// Material Table
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import InfoIcon from '@mui/icons-material/Info';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



const StakeholdersList = (props) => {
    const username = props.user.username;
    const [stakeholders, setStakeholders] = useState([]);
    let navigate = useNavigate();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        InfoIcon: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
        AddchartIcon: forwardRef((props, ref) => <AddchartIcon {...props} ref={ref} />),
        AddCircleOutlineIcon: forwardRef((props, ref) => <AddCircleOutlineIcon {...props} ref={ref} />)
    };

    useEffect(() => {
        retrieveStakeholders();
    }, []);

    const retrieveStakeholders = () => {
        StakeholderService.getAll()
            .then(response => {
                setStakeholders(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteSh = (id) => {
        StakeholderService.remove(id)
            .then(response => {
                console.log(response.data);
                alert("Stakeholders cancellato")
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleDateFilter = (term, rowData) => {
        if (term.length == 4) {
            return parseInt(term) == new Date(rowData.data_inserimento).getFullYear() ? true : false
        } else return new Date(parseInt(term.slice(6)), parseInt(term.slice(3, 5)) - 1, parseInt(term.slice(0, 2)), 12).setHours(0, 0, 0, 0) <= new Date(rowData.data_inserimento)
            ? true
            : false;
    };

    return (
        <>
            <Button variant="contained" sx={{ marginBottom: "20px" }} startIcon={<AddIcon />} onClick={() => navigate("/dashboard/stakeholders/add")}>Aggiungi contatto</Button >
            <MaterialTable
                columns={[
                    { field: 'titolo', width: 150, title: "Titolo", hidden: true, hiddenByColumnsButton: true },
                    { field: 'nome', width: 150, title: "Nome", hidden: false },
                    { field: 'cognome', width: 150, title: "Cognome", hidden: false },
                    { field: 'ragione_sociale', width: 150, title: "Ragione sociale" },
                    { field: 'categoria', width: 150, title: "Categoria", hidden: false },
                    { field: 'sesso', width: 150, title: "Sesso", hidden: true, hiddenByColumnsButton: true },
                    { field: 'data_nascita', width: 150, title: "Data di nascita", hidden: true, hiddenByColumnsButton: true },
                    { field: 'luogo_nascita', width: 150, title: "Luogo di nascita", hidden: true, hiddenByColumnsButton: true },
                    { field: 'codice_fiscale', width: 150, title: "Condice fiscale", hidden: true, hiddenByColumnsButton: true },
                    { field: 'telefono', width: 150, title: "Telefono", hidden: true, hiddenByColumnsButton: true },
                    { field: 'cellulare', width: 150, title: "Cellulare", hidden: true, hiddenByColumnsButton: true },
                    { field: 'email', width: 150, title: "Email", hidden: true, hiddenByColumnsButton: true },
                    { field: 'indirizzo', width: 150, title: "Indirizzo", hidden: true, hiddenByColumnsButton: true },
                    { field: 'civico', width: 150, title: "Civico", hidden: true, hiddenByColumnsButton: true },
                    { field: 'provincia', width: 150, title: "Provincia", hidden: true, hiddenByColumnsButton: true },
                    { field: 'citta', width: 150, title: "Città", hidden: true, hiddenByColumnsButton: true },
                    { field: 'cap', width: 150, title: "CAP", hidden: true, hiddenByColumnsButton: true },
                    {
                        field: 'data_inserimento', width: 150, title: "Primo contatto", hidden: true, hiddenByColumnsButton: true,
                        render: rowData => new Date(rowData?.data_inserimento)?.toLocaleDateString('it-IT'),
                        customFilterAndSearch: (term, rowData) => handleDateFilter(term, rowData),
                    },
                    { field: 'societa', width: 150, title: "Società", hidden: true, hiddenByColumnsButton: true },
                    { field: 'provenienza', width: 150, title: "Provenienza", hidden: true, hiddenByColumnsButton: true },
                    { field: 'partita_iva', width: 150, title: "Partita Iva", hidden: true, hiddenByColumnsButton: true },
                    { field: 'tipologia', width: 150, title: "Tipologia", hidden: true, hiddenByColumnsButton: true },
                    { field: 'fonte', width: 150, title: "Fonte", hidden: true, hiddenByColumnsButton: true },
                ]}
                options={{
                    exportButton: true,
                    exportAllData: true,
                    filtering: true,
                    filteringSelect: true,
                    columnsButton: true,
                    grouping: true,
                    paging: true,
                    pageSize: 20,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions: [5, 10, 20, 50],    // rows selection options
                    headerStyle: {
                        fontSize: 16,
                        fontWeight: "bold",
                    },
                }}
                localization={{
                    grouping: {
                        placeholder: 'Trascina qui un campo colonna'
                    },
                    pagination: {
                        labelDisplayedRows: '{from}-{to} di {count}',
                        labelRowsSelect: 'Righe',
                        firstAriaLabel: 'Prima pagina',
                        firstTooltip: 'Prima pagina',
                        previousAriaLabel: 'Pagina precedente',
                        previousTooltip: 'Pagina precedente',
                        nextAriaLabel: 'Pagina successiva',
                        nextTooltip: 'Pagina successiva',
                        lastAriaLabel: 'Ultima pagina',
                        lastTooltip: 'Ultima pagina'
                    },
                    toolbar: {
                        onRowsSelected: '{0} righe selezionate',
                        searchTooltip: 'Cerca',
                        searchPlaceholder: 'Cerca',
                        exportTitle: 'Esporta',
                        exportAriaLabel: 'Exporter',
                        showColumnsTitle: 'Mostra colonne',
                        showColumnsAriaLabel: 'Mostra colonne',
                        addRemoveColumns: 'Seleziona le colonne da visualizzare'
                    },
                    header: {
                        actions: 'Azioni'
                    },
                    body: {
                        emptyDataSourceMessage: 'Nessun contatto',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                icons={tableIcons}
                data={stakeholders}
                title="Stakeholders"
                actions={[
                    {
                        icon: tableIcons.Edit,
                        tooltip: 'Modifica',
                        disabled: username == "clubitaca",
                        onClick: (event, rowData) => {
                            window.open('/dashboard/stakeholders/' + rowData.id, '_blank', 'rel=noopener noreferrer');
                        }
                    },
                    {
                        icon: tableIcons.AddCircleOutlineIcon,
                        tooltip: 'Registra azione',
                        onClick: (event, rowData) => {
                            navigate('/dashboard/stakeholders/' + rowData.id + '/azione');
                        }
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: 'Cancella',
                        disabled: username == "clubitaca",
                        onClick: (event, rowData) => {
                            if (window.confirm('Sicur* di eliminare lo stakeholders?')) {
                                deleteSh(rowData.id)
                            }
                        }
                    }
                ]}
            />
        </>
    );
};

export default StakeholdersList;
