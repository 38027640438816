import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';

//calendar
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

//check box multiple
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';


//table
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid'; // Grid version 1


//time
import { TimeField } from '@mui/x-date-pickers/TimeField';

// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import AutorenewIcon from '@mui/icons-material/Autorenew';

//menuitem
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// input
import TextField from '@mui/material/TextField';

// service
import ActionService from "../services/ActionService";
import TableService from "../services/TableService";
import StakeholderService from "../services/StakeholderService";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.cognome,
});

const Calendar = props => {

    const initialActionState = {
        data: null,
        orario_inizio: "00:00",
        orario_fine: "00:00",
        tipologia: "",
        note: "",
        contatto_id: [],
    };

    const [Action, setAction] = useState(initialActionState);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [stakeholders, setStakeholders] = useState();

    const retrieveStakeholders = () => {
        StakeholderService.getAll()
            .then(response => {
                setStakeholders(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };


    useEffect(() => {
        retrieveTables();
        retrieveStakeholders();
    }, []);

    //new Action

    const saveAction = () => {

        const data = {
            id_act: Action.id_act,
            data: Action.data,
            orario_inizio: startTime.get('hour') + ":" + startTime.get('minute') + ":00",
            orario_fine: endTime.get('hour') + ":" + endTime.get('minute') + ":00",
            tipologia: Action.tipologia,
            note: Action.note,
        }

        Action.contatto_id.map((id) => {
            console.log((stakeholders.filter(sh => sh.id == id)[0].nome));
            ActionService.create({
                ...data, contatto_id: id,
                nome: (stakeholders.filter(sh => sh.id == id))[0].nome,
                cognome: (stakeholders.filter(sh => sh.id == id))[0].cognome,
                categoria_sh: (stakeholders.filter(sh => sh.id == id))[0].categoria
            })
                .then(response => {
                    setAction({
                        id_act: response.data.id_act,
                        data: response.data.data,
                        orario_inizio: response.data.orario_inizio,
                        orario_fine: response.data.orario_fine,
                        tipologia: response.data.tipologia,
                        note: response.data.note,
                        contatto_id: response.data.contatto_id,
                        nome: response.data.nome,
                        cognome: response.data.cognome,
                        categoria_sh: response.data.categoria_sh
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        })
        handleClose();
    };


    //start Dialog

    const [open, setOpen] = React.useState(false);

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    //end Dialog

    const rows = props.actions;
    const { id } = useParams();
    let navigate = useNavigate();


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAction({ ...Action, [name]: value });
    };

    const handleContactChange = (value) => {
        setAction({ ...Action, contatto_id: value.map((val) => val.id) });
    }

    const handleInputTimeStartChange = (value) => {
        setAction({ ...Action, orario_inizio: value });
    };

    const handleInputTimeEndChange = (value) => {
        setAction({ ...Action, orario_fine: value });
    };


    const [openDialog, setOpenDialog] = useState(false);

    const handleDialog = (e) => {
        setOpenDialog(true)

    }

    const birthdayList = () => {
        var now = dateValue.format("YYYY-MM-DD");
        var nd = now?.split("-")[2];
        var nm = now?.split("-")[1];
        var ny = now?.split("-")[0];

        const bList = stakeholders?.filter((sh) =>
            sh.data_nascita?.split("T")[0]?.split("-")[1] == nm &&
            sh.data_nascita?.split("T")[0]?.split("-")[2] == nd)

        return bList;
    }

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [dateValue, setDateValue] = useState(dayjs());

    const handleDate = (date) => {
        const data = new Date(date)
        const m = data.getMonth();
        const d = data.getDate();
        const y = data.getFullYear();
        setDateValue(date);
        setAction({ ...Action, data: (y + "-" + (((m + 1) < 10) ? "0" + (m + 1) : (m + 1)) + "-" + ((d < 10) ? "0" + d : d)) })
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {(open) ? (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"
                        contacts={stakeholders}
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Aggiungi azione di massa
                        </DialogTitle>
                        <DialogContent sx={{ marginTop: "10px" }}>
                            <TextField id="outlined-basic" label="Data" name="data" InputLabelProps={{ shrink: true }} type="date" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Action.data} onChange={handleInputChange} />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimeField
                                    label="Orario inizio"
                                    name="orario_inizio"
                                    onChange={(newValue) => setStartTime(newValue)}
                                    style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}
                                    format="HH:mm"
                                />
                                <TimeField
                                    label="Orario fine"
                                    name="orario_fine"
                                    onChange={(newValue) => setEndTime(newValue)}
                                    style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}
                                    format="HH:mm"
                                />
                            </LocalizationProvider>
                            <FormControl sx={{ minWidth: "25ch", marginTop: "10px", marginBottom: "20px", marginRight: "20px" }}>
                                <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    autowidth
                                    defaultValue={""}
                                    name="tipologia"
                                    onChange={handleInputChange}
                                    label="Tipologia"
                                >
                                    {
                                        tables.map((tab) =>
                                            (tab.riferimento == 7) ?
                                                <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                : null
                                        )
                                    }
                                </Select>
                            </FormControl>
                            {(stakeholders) ? (
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={stakeholders}
                                    disableCloseOnSelect
                                    onChange={(event, value) => handleContactChange(value)}
                                    getOptionLabel={(option) => (option.cognome) ? option.cognome + " " + option.nome : (option.ragione_sociale) ? option.ragione_sociale : ""}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {(option.cognome) ? option.cognome + " " + option.nome : option.ragione_sociale}
                                        </li>
                                    )}
                                    style={{ width: 500, marginTop: "10px", marginBottom: "20px" }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Contatti" placeholder="Cerca contatto" />
                                    )}
                                />
                            ) : null}
                            <TextField id="outlined-basic" label="Note" name="note" fullWidth multiline rows={3} variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} onChange={handleInputChange} />
                            <DialogContentText>
                                Per salvare clicca su Salva altrimenti Chiudi
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                Chiudi
                            </Button>
                            <Button onClick={saveAction}>Salva</Button>
                        </DialogActions>
                    </Dialog>) : null}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar value={dateValue} onChange={(newValue) => handleDate(newValue)} onDoubleClick={(event) => handleClickOpen(event)} />
                </LocalizationProvider>
                <Paper>
                    <Grid sx={{ padding: "20px" }}>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary">
                            🎂 Compleanni
                        </Typography>
                        {
                            (dateValue) ? birthdayList()?.map((b) => {
                                return (<Typography sx={{ marginTop: "10px", fontSize: 14 }} color="text.secondary">
                                    <Link to={"/dashboard/stakeholders/" + b.id}> {b.nome + " " + b.cognome} </Link> {" compie " + (dateValue.year() - parseInt(b.data_nascita?.split("T")[0]?.split("-")[0])) + " anni"}
                                </Typography>)
                            }) : null
                        }
                    </Grid>
                </Paper>
            </div>
        </>
    );
};

export default Calendar;



