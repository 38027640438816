import React, { useState, useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { ExportToExcel } from '../ExportToExcel'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';




//table
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

// service
import DonationService from "../services/DonationService";
import StakeholderService from "../services/StakeholderService";
import TableDataService from "../services/TableService";
import { useNavigate } from "react-router-dom";


const Donations = () => {

    const initialQuery = {
        data_inizio: null,
        data_fine: null,
        intervallo: null,
        importo: null,
        metodo: null,
        tipologia: null,
        campagna: null,
        progetto: null,
        set: false,
        nome: null,
        cognome: null,
        ragione_sociale: null,
        operatore: ">",
        val_importo: null
    }
    const [query, setQuery] = useState(initialQuery)

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [donations, setDonations] = useState([]);
    const [stakeholders, setStakeholders] = useState([]);

    const retriveDonations = () => {
        DonationService.getAll()
            .then(response => {
                setDonations(response.data);
            })
            .catch(e => {
                console.log(e);
            })
    }

    const retrieveStakeholders = () => {
        StakeholderService.getAll()
            .then(response => {
                setStakeholders(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    let rows = (!query.set) ? donations :
        donations.filter(don =>
            ((query.data_inizio && query.data_inizio != "") ?
                (new Date(query.data_inizio) <= (new Date(don.data_accredito))) : true)
            && ((query.data_fine && query.data_fine != "") ?
                ((new Date(don.data_accredito)) <= (new Date(query.data_fine))) : true)
            && ((query.metodo) ? query.metodo == don.metodo : true) && ((query.tipologia) ? query.tipologia == don.tipologia : true) &&
            ((query.campagna) ? query.campagna == don.campagna : true) && ((query.progetto) ? query.progetto == don.progetto : true) &&
            ((query.val_importo) ?
                (query.operatore == ">") ? don.importo > query.val_importo :
                    (query.operatore == ">=") ? don.importo >= query.val_importo :
                        (query.operatore == "<") ? don.importo < query.val_importo :
                            (query.operatore == "<=") ? don.importo <= query.val_importo :
                                (query.operatore == "=") ? don.importo == query.val_importo :
                                    null
                : null
            )
        )

    let rowsExport = rows.map(r => {
        let a = stakeholders.find(s => r.donatore_id == s.id);
        return { ...r, ...a }
    })
    console.log(rowsExport);

    useEffect(() => {
        retriveDonations();
        retrieveStakeholders();
        retrieveTables();
    }, []);


    const handleClickDate = (event) => {
        let offset = event.target.value;
        let now = Date.now();
        let setDate = new Date(now - 1000 * 60 * 60 * 24 * offset).toISOString().split('T')[0];
        console.log(setDate);
        console.log(new Date(now).toISOString().split('T')[0]);
        setQuery({ ...query, intervallo: offset, data_inizio: setDate, data_fine: new Date(now).toISOString().split('T')[0], set: true })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setQuery({ ...query, [name]: value, set: true });
    };

    // const table
    const columns = [
        { field: 'nome', width: 150, headerName: "Nome" },
        { field: 'cognome', width: 150, headerName: "Cognome" },
        { field: 'ragione_sociale', width: 150, headerName: "Ragione sociale" },
        { field: 'data_accredito', headerName: 'Data accredito', width: 230, type: "date" },
        { field: 'importo', headerName: 'Importo', type: 'number', width: 130 },
        { field: 'metodo', headerName: 'Metodo' },
        { field: 'campagna', headerName: 'Campagna', width: 230 },
        { field: 'tipologia', headerName: 'Tipologia', width: 230 },
        { field: 'progetto', headerName: 'Progetto', width: 300 },
    ];


    const navigate = useNavigate();

    return (
        <>
            <Grid xs={12} md={12} sx={{ marginBottom: "20px" }} >
                <Paper >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 2, width: '25ch' },
                            '& .MuiFormControl-root': { m: 2, width: '25ch' },
                            '& .MuiButton-root': { m: 2, float: "right" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="Da" name="data_inizio" onChange={handleInputChange} value={(!query.data_inizio) ? new Date(null) : query.data_inizio} InputLabelProps={{ shrink: true }} type="date" variant="outlined" />
                        <TextField id="outlined-basic" label="A" name="data_fine" onChange={handleInputChange} InputLabelProps={{ shrink: true }} type="date" variant="outlined" value={(!query.data_fine) ? new Date(null) : query.data_fine} />
                        <FormControl>
                            <InputLabel id="demo-simple-select-autowidth-label">Intervallo giorni</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.intervallo) ? query.intervallo : ""}
                                autowidth
                                onChange={handleClickDate}
                                label="Intervallo giorni"
                            >
                                <MenuItem value={null}>Seleziona</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={60}>60</MenuItem>
                                <MenuItem value={90}>90</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <FormControl>
                            <InputLabel id="demo-simple-select-autowidth-label">Metodo di pagamento</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.metodo) ? query.metodo : ""}
                                autowidth
                                name="metodo"
                                onChange={handleInputChange}
                                label="Metodo di pagamento"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 1) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.tipologia) ? query.tipologia : ""}
                                autowidth
                                name="tipologia"
                                onChange={handleInputChange}
                                label="Tipologia"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 2) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl >
                            <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.campagna) ? query.campagna : ""}
                                autowidth
                                name="campagna"
                                onChange={handleInputChange}
                                label="Campagna"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 3) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-autowidth-label">Progetto</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={(query.progetto) ? query.progetto : ""}
                                autowidth
                                name="progetto"
                                onChange={handleInputChange}
                                label="Progetto"
                            >
                                <MenuItem value={null}>Tutte</MenuItem>
                                {
                                    tables.map((tab) =>
                                        (tab.riferimento == 4) ?
                                            <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                            : null
                                    )
                                }
                            </Select>
                        </FormControl><br />
                        <FormControl >
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                autowidth
                                value={query.operatore}
                                name="operatore"
                                onChange={handleInputChange}
                            >
                                <MenuItem value={"="}>Uguale</MenuItem>
                                <MenuItem value={">"}>Maggiore</MenuItem>
                                <MenuItem value={">="}>Maggiore uguale</MenuItem>
                                <MenuItem value={"<"}>Minore</MenuItem>
                                <MenuItem value={"<="}>Minore uguale</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField id="outlined-basic" label="Importo donazione" variant="outlined" name="val_importo" value={(query.val_importo) ? query.val_importo : ""} onChange={handleInputChange} />
                        <Button variant="contained" startIcon={<RestartAltIcon />} onClick={() => setQuery(initialQuery)}>Reset</Button >
                    </Box>
                </Paper >
            </Grid>
            <Paper>
                <Grid xs={12} md={12} sx={{ textAlign: "end" }} >
                    <ExportToExcel apiData={rowsExport} fileName={"Donazioni"} /> <br />
                    <div style={{ height: 600, width: '100%', marginBottom: "150px" }}>
                        <DataGrid
                            rows={rows.map((row) =>
                            ({
                                ...row,
                                data_accredito: new Date(row.data_accredito)
                            }))}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id_don}
                            sx={{ height: "600px", margin: "10px" }}
                            rowsPerPageOptions={[5]}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'data_accredito', sort: 'desc' }],
                                },
                            }}
                            onRowDoubleClick={(e) =>
                                navigate("/dashboard/stakeholders/" + e.row.donatore_id)}
                        />
                    </div >
                </Grid>
            </Paper>
        </>
    );
};

export default Donations;