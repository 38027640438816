import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MainListItems from './listItems';
import TutorialsList from './TutorialsList';
import ContactsList from './ContactsList';
import SpeedDial from './SpeedDial';
import Grid from '@mui/material/Grid'; // Grid version 1
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';


//component
import Contact from './Contact';
import AddContact from './AddContact';
import OutputsList from './OutputsList';
import Calendar from './Calendar';
import TablesList from './Table';
import Chart from './Chart'
import Stakeholder from './Stakeholder';
import Report from './Report';
import Output from "./Output";

//service
import ContactService from '../services/ContactService';
import StakeholdersList from './StakeholdersList';
import Donations from './Donations';
import ActionService from '../services/ActionService';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://progettoitacafirenze.org">
                Progetto Itaca Firenze
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

function DashboardContent(props) {
    const [open, setOpen] = React.useState(true);
    const user = props.user;
    const logOut = props.logOut;
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: '#4b8854'
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Progetto Itaca Firenze
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Avatar> {user.nome.charAt(0) + user.cognome.charAt(0)}</Avatar>
                        </Stack>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ marginLeft: 1 }}
                        >
                            <a href="/" className="nav-link" style={{ color: "white", fontSize: "12pt" }} onClick={logOut}>
                                ESCI
                            </a>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems user={user} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >

                    <Toolbar />
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={12}>
                                <Routes>
                                    <Route path="/tutorials" element={<TutorialsList />} />
                                    <Route path="/stakeholders" element={<StakeholdersList user={user} />} />
                                    <Route path="/stakeholders/add" element={<Stakeholder />} />
                                    <Route path="/donazioni" element={<Donations />} />
                                    <Route path="/uscite" element={<Output />} />
                                    <Route path="/tables" element={<TablesList />} />
                                    <Route path="/report" element={<Report />} />
                                    <Route path="/outputs" element={<OutputsList />} />
                                    <Route path="/stakeholders/:id" element={<Stakeholder user={user} />} />
                                    <Route path="/stakeholders/:id/:azione" element={<Stakeholder panel={4} user={user} />} />
                                </Routes>
                            </Grid>
                            <Grid xs={8} md={8}>
                                <Paper >
                                    <Routes>
                                        <Route exact path="/" element={<Chart />} />
                                    </Routes>
                                </Paper>
                            </Grid>
                            <Grid xs={0.5} md={0.5} />
                            <Grid xs={3} md={3}>
                                <Paper>
                                    <Routes>
                                        <Route path="/" element={<Calendar />} />
                                    </Routes>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard(props) {
    const { user, logOut } = props;
    return <DashboardContent user={user} logOut={logOut} />;
}