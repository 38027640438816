import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const ExportToExcel = ({ apiData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <>
            <Button sx={{ marginBottom: "10px", marginTop: "20px", marginRight: "20px" }} variant="outlined" onClick={(e) => exportToCSV(apiData, fileName)} endIcon={<FileDownloadIcon />}>
                Esporta
            </Button>
        </>
    );
};