import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// service
import DonationService from '../services/DonationService';

function createData(importo, data) {
    return { importo, data };
}


const Chart = () => {
    const theme = useTheme();
    const [donations, setDonations] = useState();
    const [result, setResult] = useState([]);

    async function handleChange(event) {
        await setDonations(DonationService.findByYear(event.target.value)
            .then(response => response.data)
            .catch(e => {
                console.log(e);
            }));
        xy();
    }

    function xy() {
        const arr = donations?.map((don) => createData(don.importo, (new Date(don.data_accredito)).getMonth() + 1));

        arr?.map((a, i) => {
            var add = false;
            for (let index = 0; index < result.length; index++) {
                if ((a.data) == result[index].data) {
                    result[index].importo += a.importo;
                    add = true;
                }
            }
            if (!add) {
                setResult(...result, { data: a.data, importo: a.importo })
            }
        });
    }



    return (
        <React.Fragment>
            <FormControl style={{ margin: "20px", minWidth: 250 }}>
                <InputLabel id="demo-simple-select-label">Seleziona anno</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Seleziona anno"
                    onChange={handleChange}
                >
                    <MenuItem value={null}>Seleziona</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                </Select>
            </FormControl><br />
            <ResponsiveContainer>
                <LineChart
                    data={result}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 1000,
                        left: 24,
                    }}
                >
                    <XAxis
                        dataKey="data"
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={theme.typography.body2}
                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.primary,
                                ...theme.typography.body1,
                            }}
                        >
                            Importo (€)
                        </Label>
                    </YAxis>
                    <Line
                        isAnimationActive={false}
                        type="monotone"
                        dataKey="importo"
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>

    );
}

export default Chart;