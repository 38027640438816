import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid'; // Grid version 1
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ExportToExcel } from '../ExportToExcel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import clsx from 'clsx';


//component
import ReportTable from './ReportTableAvgTime';
import ReportTableTimeSlots from "./ReportTableTimeSlots";
import TableAttivi from './TableAttivi'


//data picker
import dayjs from 'dayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



// servive
import StakeholderService from "../services/StakeholderService";
import ActionService from "../services/ActionService";
import TableDataService from "../services/TableService";


// chip select
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const month = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];

function getStyles(name, monthS, theme) {
    return {
        fontWeight:
            monthS.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const columns = [
    { field: 'nome', headerName: 'Nome' },
    { field: 'cognome', headerName: 'Cognome' },
    { field: 'categoria_sh', headerName: 'Categoria' },
    { field: 'data', headerName: 'Data', type: "date" },
    { field: 'orario_inizio', headerName: 'Ora inizio', type: 'number' },
    { field: 'orario_fine', headerName: 'Ora fine' },
    {
        field: 'tot_ore', headerName: 'Totale ore',
        cellClassName: (params) => {

            const row = params.row;

            var time3 = 0;

            var time1 = row.orario_inizio?.split(':')
            var time2 = row.orario_fine?.split(':')

            var newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
            var newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

            time3 = newTime2 - newTime1;

            var m = time3 % 60;
            var h = (time3 - m) / 60;

            return clsx('super-app', {
                check: h == 0 || h >= 8,
            });
        },
    },
    { field: 'tipologia', headerName: 'Tipologia' },
    { field: 'note', headerName: 'Note', width: "100ch" },
];




const Report = props => {

    const [refresh, setRefresh] = React.useState(false);

    const initialQuery = {
        data_inizio: null,
        data_fine: null,
        intervallo: null,
        tipologia: null,
        categoria: null,
        operatore: null,
        valore: null,
        set: false
    }

    const [query, setQuery] = useState(initialQuery);


    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleClickDate = (event) => {
        let offset = event.target.value;
        let data_fine = new Date(query.data_fine)
        let setDate = new Date(data_fine - 1000 * 60 * 60 * 24 * offset).toISOString().split('T')[0];
        setQuery({ ...query, intervallo: offset, data_inizio: setDate, set: true })
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setQuery({ ...query, [name]: value, set: true });
    };




    useEffect(() => {
        retriveStakeholders();
        retriveActions();
        retrieveTables();
    }, []);

    const [shList, setShList] = useState([])
    const retriveStakeholders = () => {
        StakeholderService.getAll()
            .then(response => {
                setShList(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const [actionsList, setActionsList] = useState([])
    const retriveActions = () => {
        ActionService.getAll()
            .then(response => {
                setActionsList(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const countCat = (rows, tip) => {
        let array = [];
        rows.map((row) =>
            !array.includes(row.contatto_id) && row.tipologia == tip ? array.push(row.contatto_id) : null
        )
        return array.length
    }

    const countWese = (rows) => {
        let array = [];
        rows.map((row) =>
            !array.includes(row.contatto_id) && row.tipologia == "WESE" ? array.push(row.contatto_id) : null
        )
        return array.length
    }

    const countVol = (rows) => {
        let array = [];
        rows.map((row) =>
            !array.includes(row.contatto_id) && row.tipologia == "VOL" ? array.push(row.contatto_id) : null
        )
        return array.length
    }

    const countSh = (rows) => {
        let array = [];
        rows.map((row) =>
            !array.includes(row.contatto_id) ? array.push(row.contatto_id) : null
        )
        return array.length
    }

    const sumTime = (rows) => {

        var time3 = "00";

        rows?.map((row) => {

            var newTime1 = 0;
            var newTime2 = 0;

            var time1 = row.orario_inizio?.split(':')
            var time2 = row.orario_fine?.split(':')

            newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
            newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

            time3 = time3 + newTime2 - newTime1;
        }
        )
        var m = time3 % 60;
        var h = (time3 - m) / 60;
        return (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m)
    }


    const rows = (!query.set) ? actionsList : actionsList.filter(act =>
        ((query.data_inizio && query.data_inizio != "") ?
            ((new Date(query.data_inizio) <= (new Date(act.data)))) : true)
        && ((query.data_fine && query.data_fine != "") ?
            ((new Date(act.data)) <= (new Date(query.data_fine))) : true)
        && ((query.tipologia) ? query.tipologia == act.tipologia : true) && ((act.categoria_sh && query.categoria) ? act.categoria_sh.includes(query.categoria)
            : true));

    const [report, setReport] = useState(0);

    const buildReport1 = (data) => {

        const rows =
            ({
                trenta: data.filter(fil => new Date(fil.data) >= t_day).length,
                sessanta: data.filter(fil => new Date(fil.data) >= s_day).length,
                novanta: data.filter(fil => new Date(fil.data) >= n_day).length,
            })

        return rows
    }

    const day = new Date(query.data_fine);
    const t_day = new Date(day.setDate(new Date().getDate() - 30));
    const s_day = new Date(day.setDate(new Date().getDate() - 30));
    const n_day = new Date(day.setDate(new Date().getDate() - 30));

    const freqMediaReport1 = (rows, fine) => {
        let a = rows.filter(fil => new Date(fil.data) >= fine);
        let dates =
            a.map((row) =>
            ({
                data: row.data,
                count: rows.filter(r => r.data === row.data).length
            })
            )
        let array = [...new Map(dates.map(item => [item["data"], item])).values()];
        let ab = (array.map((a) => a.count).reduce((partialSum, a) => partialSum + a, 0));
        console.log(ab);
        return ((array.map((a) => a.count).reduce((partialSum, a) => partialSum + a, 0)) / array.length) || 0;
    }

    const countHours = (row) => {
        var time3 = 0;

        var time1 = row.orario_inizio?.split(':')
        var time2 = row.orario_fine?.split(':')

        var newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
        var newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

        time3 = newTime2 - newTime1;

        var m = time3 % 60;
        var h = (time3 - m) / 60;
        return !isNaN(m) ? (h < 10 ? "0" + h : h) + ":" + (m < 10 ? "0" + m : m) : "00:00"
    }

    const checkTime = (row) => {
        var time3 = 0;

        var time1 = row.orario_inizio?.split(':')
        var time2 = row.orario_fine?.split(':')

        var newTime1 = parseInt(time1[0]) * 60 + parseInt(time1[1]);
        var newTime2 = parseInt(time2[0]) * 60 + parseInt(time2[1]);

        time3 = newTime2 - newTime1;

        var m = time3 % 60;
        var h = (time3 - m) / 60;
        return h == 0 || h >= 8
    }

    const handleReport = (event) => {
        setReport(event.target.value);
        setQuery({ ...query, data_inizio: null, intervallo: null });
    }

    const switchReport = (report) => {

        switch (report) {
            case 1:
                return (
                    <Grid container xs={12} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    30 GG
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attivi
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countSh(rows.filter(fil => new Date(fil.data) >= t_day))
                                    }
                                </Typography>
                                {/* 
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attività
                                </Typography>
                                                               <Typography variant="h4" >
                                    {
                                        buildReport1(rows).trenta
                                    }
                                </Typography> */}
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Frequenza media
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        Math.ceil(freqMediaReport1(rows, new Date(day.setDate(day.getDate() - 30))))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    60 GG
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attivi
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countSh(rows.filter(fil => new Date(fil.data) >= s_day))
                                    }
                                </Typography>
                                {/* 
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attività
                                </Typography>
                                                               <Typography variant="h4" >
                                    {
                                        buildReport1(rows).sessanta
                                    }
                                </Typography> */}
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Frequenza media
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        Math.ceil(freqMediaReport1(rows, new Date(day.setDate(day.getDate() - 30))))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    90 GG
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attivi
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countSh(rows.filter(fil => new Date(fil.data) >= n_day))
                                    }
                                </Typography>
                                {/* 
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale attività
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        buildReport1(rows).novanta
                                    }
                                </Typography> */}
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Frequenza media
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        Math.ceil(freqMediaReport1(rows, new Date(day.setDate(day.getDate() - 30))))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    Numero totale Soci
                                </Typography>
                                <Typography variant="h4" sx={{ marginTop: "10px" }} >
                                    {
                                        shList?.filter(fil => fil.categoria == "Socio Club" && new Date(fil.data_socio) <= new Date(query.data_fine)).length
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    WOD
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "WOD")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "WOD"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    JS
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "JS")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "JS"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    WESE
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countWese(rows, "WESE")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "WESE"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    VOL
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countVol(rows, "VOL")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "VOL"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    IR
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "IR")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "IR"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    RO
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "RO")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "RO"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    TE
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "TE")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "TE"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    W+JS
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "W+JS")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "W+JS"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    WESE-OSPITI
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "WESE-OSPITI")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "WESE-OSPITI"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    WOD Ospite
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "WOD Ospite")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "WOD Ospite"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid xs={2} sx={{ marginTop: "10px", marginLeft: "10px" }}>
                            <Paper sx={{ textAlign: "center" }}>
                                <Typography sx={{ fontSize: 20, backgroundColor: "#4b8854", color: "white" }} color="text.secondary" >
                                    WOD+WESE
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Numero totale
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        countCat(rows, "WOD+WESE")
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14, marginTop: "10px" }} color="text.secondary" >
                                    Totale ore
                                </Typography>
                                <Typography variant="h4" >
                                    {
                                        sumTime(rows.filter(fil => fil.tipologia == "WOD-WESE"))
                                    }
                                </Typography>
                            </Paper>
                        </Grid >
                    </>
                );
            case 3:
                return ((query.data_inizio && query.data_fine) ?
                    <ReportTable rows={rows} query={query} />
                    : null
                );

            case 4:
                return ((query.data_inizio && query.data_fine) ?
                    <ReportTableTimeSlots rows={rows} query={query} />
                    : null
                )
        }
    }
    const navigate = useNavigate();
    const [valore, setValore] = useState(dayjs());

    return (
        (shList) ? (
            <Box sx={{ flexGrow: 1 }}>
                <Grid xs={12} md={12} sx={{ marginBottom: "20px" }}>
                    <Paper >
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 2, width: '25ch' },
                                '& .MuiFormControl-root': { m: 2, width: '25ch' },
                                '& .MuiButton-root': { m: 2, float: "right" },
                            }}
                            noValidate
                            autoComplete="off">
                            <TextField id="outlined-basic" label="Da" name="data_inizio" disabled={report == 1 ? true : false} onChange={handleInputChange} value={(!query.data_inizio) ? new Date(null) : query.data_inizio} InputLabelProps={{ shrink: true }} type="date" variant="outlined" />
                            <TextField id="outlined-basic" label="A" name="data_fine" onChange={handleInputChange} InputLabelProps={{ shrink: true }} type="date" variant="outlined" value={(!query.data_fine) ? new Date(null) : query.data_fine} />
                            <FormControl disabled={report == 1 ? true : false}>
                                <InputLabel id="demo-simple-select-autowidth-label">Intervallo giorni</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={(query.intervallo) ? query.intervallo : ""}
                                    autowidth
                                    disabled={report == 1 ? true : false}

                                    onChange={handleClickDate}
                                    label="Intervallo giorni"
                                >
                                    <MenuItem value={null}>Seleziona</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={60}>60</MenuItem>
                                    <MenuItem value={90}>90</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={(query.tipologia) ? query.tipologia : ""}
                                    autowidth
                                    name="tipologia"
                                    onChange={handleInputChange}
                                    label="Tipologia"
                                >
                                    <MenuItem value={null}>Tutte</MenuItem>
                                    {
                                        tables.map((tab) =>
                                            (tab.riferimento == 7) ?
                                                <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                : null
                                        )
                                    }
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    value={(query.categoria) ? query.categoria : ""}
                                    autowidth
                                    name="categoria"
                                    onChange={handleInputChange}
                                    label="Categoria"
                                >
                                    <MenuItem value={null}>Tutte</MenuItem>
                                    {
                                        tables.map((tab) =>
                                            (tab.riferimento == 12) ?
                                                <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                : null
                                        )
                                    }
                                </Select>
                            </FormControl>
                            <Button variant="contained" startIcon={<RestartAltIcon />} onClick={() => setQuery(initialQuery)}>Reset</Button >
                            <FormControl>
                                <InputLabel id="demo-simple-select-autowidth-label">Seleziona report</InputLabel>
                                <Select
                                    labelId="demo-simple-select-autowidth-label"
                                    id="demo-simple-select-autowidth"
                                    autowidth
                                    onChange={handleReport}
                                    label="Seleziona report"
                                >
                                    <MenuItem value={0}>Seleziona</MenuItem>
                                    <MenuItem value={1}>Report iscritti/attivi</MenuItem>
                                    <MenuItem value={2}>Report attività</MenuItem>
                                    <MenuItem value={3}>Report media ore</MenuItem>
                                    <MenuItem value={4}>Report fasce orarie</MenuItem>
                                </Select>
                            </FormControl><br />
                        </Box>
                    </Paper>
                    <Grid container sx={{ marginBottom: "20px" }} >
                        {switchReport(report)}
                    </Grid>
                    <Paper >
                        <Grid xs={12} md={12} sx={{ textAlign: "end" }} >
                            <ExportToExcel apiData={rows} fileName={"Report"} />
                        </Grid>
                        <Grid xs={12} md={12} >
                            <Box
                                sx={{
                                    height: 600,
                                    width: 'auto%',
                                    margin: "10px",
                                    '& .super-app.check': {
                                        backgroundColor: ' #e0b73c8c',
                                        fontWeight: '600',
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={rows.map((row) =>
                                    ({
                                        ...row,
                                        data: new Date(row.data),
                                        orario_inizio: row.orario_inizio?.slice(0, 5),
                                        orario_fine: row.orario_fine?.slice(0, 5),
                                        tot_ore: countHours(row)
                                    }))}
                                    columns={columns}
                                    getRowId={(row) => row.id_act}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        },
                                    }}
                                    pageSizeOptions={[10, 10]}
                                    onRowDoubleClick={(e) =>
                                        navigate("/dashboard/stakeholders/" + e.row.contatto_id + "/action")}

                                />
                            </Box>
                        </Grid>
                    </Paper>
                </Grid>

            </Box>
        ) : null
    )

}

export default Report;