import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

//table
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

// input
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';



// component
import Donation from "./DonationsSH";

// service
import DonationService from "../services/DonationService";
import TableDataService from "../services/TableService";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Donations = props => {

    const initialDonationState = {
        data_accredito: null,
        importo: 0.0,
        metodo: "",
        tipologia: "",
        campagna: "",
        progetto: "",
        ringraziamento: "",
        ricevuta: "",
        note: "",
        donatore_id: props.sh.id,
        nome: props.sh.nome,
        cognome: props.sh.cognome,
        ragione_sociale: props.sh.ragione_sociale
    };

    const [Donation, setDonation] = useState(initialDonationState);
    const [DonationsList, setDonationsList] = useState();

    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableDataService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retriveDonationsId = () => {
        DonationService.get(Donation.donatore_id)
            .then(response => {
                setDonationsList(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    useEffect(() => {
        retriveDonationsId();
        retrieveTables();
    }, []);

    //new Donation

    const saveDonation = () => {

        DonationService.create(Donation)
            .then(response => {
                setDonation({
                    id_don: response.data.id_don,
                    data_accredito: new Date(response.data.data_accredito),
                    importo: response.data.importo,
                    metodo: response.data.metodo,
                    tipologia: response.data.tipologia,
                    campagna: response.data.campagna,
                    progetto: response.data.progetto,
                    ringraziamento: response.data.ringraziamento,
                    ricevuta: response.data.ricevuta,
                    note: response.data.note,
                    donatore_id: response.data.donatore_id
                });
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };

    // const table
    const columns = [
        { field: 'data_accredito', headerName: 'Data accredito', width: 230, type: "date" },
        { field: 'importo', headerName: 'Importo', type: 'number', width: 130 },
        { field: 'metodo', headerName: 'Metodo' },
        { field: 'campagna', headerName: 'Campagna', width: 230 },
        { field: 'tipologia', headerName: 'Tipologia', width: 230 },
        { field: 'progetto', headerName: 'Progetto', width: 230 },
        { field: 'note', headerName: 'Note', width: 330 },
    ];

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialog = (e) => {
        setOpenDialog(true)

    }
    //start Dialog


    const [open, setOpen] = React.useState(false);
    const [newDonation, setnewDonation] = useState(false);
    const apiRef = useGridApiRef();

    const handleClickOpen = (e) => {
        setOpen(true);
        setnewDonation(false);
        setDonation(e.row);
    };

    const handleNewDonation = () => {
        setOpen(true);
        setnewDonation(true);
        setDonation(initialDonationState);
    }

    const handleClose = () => {
        setOpen(false);
    }

    //end Dialog
    var sum = 0;
    if (DonationsList) {
        var dates =
            DonationsList.map((row) =>
                row.data_accredito);
        var donDate =
            DonationsList.map((row) =>
                [row.data_accredito, row.importo]);
        const today = new Date();
        const today1ya = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        sum =
            donDate.reduce((partialSum, row) =>
                (new Date(row[0]) >= today1ya) ? partialSum + row[1] : partialSum + 0
                , 0);
    }

    const { id } = useParams();
    let navigate = useNavigate();
    const info = (props.v) ? props.v : false;
    const addAction = (props.addAction) ? props.addAction : false;


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDonation({ ...Donation, [name]: (name == "data_accredito") ? new Date(value) : value });
    };

    const handleUpdateAllRows = () => {
        const rowIds = apiRef.current.getAllRowIds();
        apiRef.current.updateRows(
            rowIds.map((rowId) => ({ id: rowId })),
        );
    };


    const updateDonation = () => {
        DonationService.update(Donation.id_don, Donation)
            .then(response => {
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };

    const deleteDonation = (id) => {
        DonationService.remove(id)
            .then(response => {
                console.log(response.data);
                alert("Donazione cancellata")
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };

    return (
        <>
            {
                (DonationsList) ? (
                    <div style={{ height: 600, width: '100%', marginBottom: "150px" }}>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                        >
                            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                {newDonation ? "Aggiungi donazione" : "Modifica donazione"}
                            </DialogTitle>
                            <DialogContent sx={{ marginTop: "10px" }}>
                                <TextField id="outlined-basic" label="Data accredito" name="data_accredito" InputLabelProps={{ shrink: true }} type="date" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Donation.data_accredito?.toISOString().split('T')[0]} disabled={info} onChange={handleInputChange} />
                                <TextField id="outlined-basic" InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                                    type="number" label="Importo" name="importo" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px", width: "20ch" }} value={Donation.importo} disabled={info} onChange={handleInputChange} />
                                <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Metodo di pagamento</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.metodo) ? Donation.metodo : ""}
                                        autowidth
                                        name="metodo"
                                        onChange={handleInputChange}
                                        label="Metodo di pagamento"
                                    >
                                        {
                                            tables.map((tab) =>
                                                (tab.riferimento == 1) ?
                                                    <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                    : null
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.tipologia) ? Donation.tipologia : ""}
                                        autowidth
                                        name="tipologia"
                                        onChange={handleInputChange}
                                        label="Tipologia"
                                    >
                                        {
                                            tables.map((tab) =>
                                                (tab.riferimento == 2) ?
                                                    <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                    : null
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.campagna) ? Donation.campagna : ""}
                                        autowidth
                                        name="campagna"
                                        onChange={handleInputChange}
                                        label="Campagna"
                                    >
                                        {
                                            tables.map((tab) =>
                                                (tab.riferimento == 3) ?
                                                    <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                    : null
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: "20ch", marginBottom: "20px", marginRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Progetto</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.progetto) ? Donation.progetto : ""}
                                        autowidth
                                        name="progetto"
                                        onChange={handleInputChange}
                                        label="Progetto"
                                    >
                                        {
                                            tables.map((tab) =>
                                                (tab.riferimento == 4) ?
                                                    <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                                    : null
                                            )
                                        }
                                    </Select>
                                </FormControl><br />
                                <FormControl sx={{ marginBottom: "20px", marginRight: "20px", width: "12ch" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Grazie</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.ringraziamento) ? Donation.ringraziamento : ""}
                                        autowidth
                                        name="ringraziamento"
                                        onChange={handleInputChange}
                                        label="Grazie"
                                    >
                                        <MenuItem value={"Si"}>Si</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginBottom: "20px", marginRight: "20px", width: "12ch" }}>
                                    <InputLabel id="demo-simple-select-autowidth-label">Ricevuta</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        value={(Donation.ricevuta) ? Donation.ricevuta : ""}
                                        autowidth
                                        name="ricevuta"
                                        onChange={handleInputChange}
                                        label="Ricevuta"
                                    >
                                        <MenuItem value={"Si"}>Si</MenuItem>
                                        <MenuItem value={"No"}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                <br /><TextField id="outlined-multiline-static" fullWidth label="Note" name="note" multiline rows={3} variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={Donation.note} onChange={handleInputChange} />
                                <DialogContentText>
                                    Per salvare clicca su {newDonation ? "Salva" : "Aggiorna"} altrimenti Chiudi
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button startIcon={<DeleteIcon />} color="error" onClick={() => deleteDonation(Donation.id_don)}>Cancella</Button>
                                <Button onClick={handleClose}>
                                    Chiudi
                                </Button>
                                <Button onClick={newDonation ? saveDonation : updateDonation}>{newDonation ? "Salva" : "Aggiorna"}</Button>
                            </DialogActions>
                        </Dialog>

                        <b>Data prima donazione:</b> {new Date(dates.sort((data1, data2) =>
                            new Date(data1) - new Date(data2))[0]).toLocaleDateString()
                        } <br />
                        <b>Data ultima donazione:</b> {new Date(dates[dates.length - 1]).toLocaleDateString()}<br />
                        <b>Donazioni ultimi 12 mesi: </b>
                        {
                            sum
                        }€
                        <br />
                        <br />
                        <Button variant="contained" sx={{ marginBottom: "20px" }} startIcon={<AddIcon />} onClick={handleNewDonation}>Aggiungi donazione</Button >
                        <Button variant="contained" sx={{ marginBottom: "20px", float: "right" }} endIcon={<AutorenewIcon />} onClick={retriveDonationsId}>
                            Aggiorna
                        </Button>
                        <DataGrid
                            rows={DonationsList.map((row) =>
                            ({
                                ...row,
                                data_accredito: new Date(row.data_accredito)
                            }))}
                            columns={columns}
                            pageSize={5}
                            getRowId={(row) => row.id_don}
                            sx={{ height: "600px" }}
                            rowsPerPageOptions={[5]}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'data_accredito', sort: 'desc' }],
                                },
                            }}
                            onRowClick={(e) =>
                                handleClickOpen(e)}
                        />
                    </div >
                ) : null
            }
        </>
    );
};

export default Donations;