import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


// tabs
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// multiple select
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Theme, useTheme } from '@mui/material/styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';



// service
import StakeholderService from "../services/StakeholderService";
import TableService from "../services/TableService";

// components
import Output from "./OutputSH";


// mail
import emailjs from '@emailjs/browser';
import Donations from "./DonationsSH";
import AddDonation from "./AddDonation";
import Actions from "./Actions";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}


const Stakeholder = props => {
    const { id } = useParams();
    let navigate = useNavigate();
    const addAction = (props.panel == 4) ? true : false;
    const info = (addAction && props.user.username == "clubitaca") ? true : false;
    const act = (props?.user?.username == "clubitaca" || props?.user?.username == "staffclubitaca");

    const initialStakeholderState = {
        id: null,
        titolo: null,
        attacco_lettera: null,
        ragione_sociale: null,
        nome: null,
        cognome: null,
        codice_fiscale: null,
        partita_iva: null,
        civico: null,
        indirizzo: null,
        cap: null,
        citta: null,
        provincia: null,
        telefono: null,
        cellulare: null,
        email: null,
        note: null,
        data_nascita: null,
        luogo_nascita: null,
        sesso: null,
        fonte: null,
        data_inserimento: null,
        comunicazioni_email: true,
        comunicazioni_cartacee: true,
        consenso_invio_materiale_informativo: true,
        consenso_ringraziamaneti: true,
        consenso_SMS: true,
        consenso_contatto_telefonico: true,
        donatore: null,
        categoria: null,
        tipo_donatore: null,
        stato: null,
        data_decesso: null,
        tessera: null,
        pec: null,
        codice_sdi: null,
        donazioni_anno_corrente: null,
        totale_donazioni: null,
        n_donazioni: null,
        termometro_dimensione: null,
        termometro_fedelta: null,
        termometro_temperatura: null,
        data_prima_donazione: null,
        importo_prima_donazione: null,
        mod_prima_donazione: null,
        data_ultima_donazione: null,
        importo_ultima_donazione: null,
        mesi_da_ultima_donazione: null,
        utente_ultima_modifica: null,
        gdpr_2016_979_consenso_il: new Date(),
        gdpr_2016_979_negato_il: null,
        gdpr_2016_979_consenso: true,
        dlgs_196_2003_consenso_il: new Date(),
        dlgs_196_2003_negato_il: null,
        dlgs_196_2003_consenso: true,
        tipologia: null,
        titolo_studio: null,
        professione: null,
        stato_civile: null,
        figli: null,
        corso_faf: null,
        testo_corso_faf: null,
        corso_vol: null,
        testo_corso_vol: null,
        data_recesso: null,
        data_socio: null,
        tipologia_contratto: null,
        data_assunzione: null,
        data_colloquio_vol_faf: null,
        data_colloquio_socio: null,
        data_ospite: null,
        invalidita: null,
        perc_invalidita: null,
        riconoscimento_handicap: null,
        iscrizione_liste_collocamento: null,
        pensione: null,
        data_primo_contatto_vol: null,
        tipo_vol: null,
        disponibilita: null
    };


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [currentStakeholder, setCurrentStakeholder] = useState(initialStakeholderState);

    const getStakeholder = id => {
        StakeholderService.get(id)
            .then(response => {
                setCurrentStakeholder({
                    ...response.data,
                    categoria: (response.data.categoria) ? response.data.categoria.split(",").map((item) => item.trim()) : null,
                    tipo_vol: (response.data.tipo_vol) ? response.data.tipo_vol.split(",").map((item) => item.trim()) : null,
                    data_nascita: (response.data.data_nascita) ? response.data.data_nascita.split("T")[0] : null,
                    data_inserimento: (response.data.data_inserimento) ? response.data.data_inserimento.split("T")[0] : null,
                    data_decesso: (response.data.data_decesso) ? response.data.data_decesso.split("T")[0] : null,
                    data_prima_donazione: (response.data.data_prima_donazione) ? response.data.data_prima_donazione.split("T")[0] : null,
                    data_ultima_donazione: (response.data.data_ultima_donazione) ? response.data.data_ultima_donazione.split("T")[0] : null,
                    gdpr_2016_979_consenso_il: (response.data.gdpr_2016_979_consenso_il) ? response.data.gdpr_2016_979_consenso_il.split("T")[0] : null,
                    dlgs_196_2003_consenso_il: (response.data.dlgs_196_2003_consenso_il) ? response.data.dlgs_196_2003_consenso_il.split("T")[0] : null,
                    data_recesso: (response.data.data_recesso) ? response.data.data_recesso.split("T")[0] : null,
                    data_socio: (response.data.data_socio) ? response.data.data_socio.split("T")[0] : null,
                    data_assunzione: (response.data.data_assunzione) ? response.data.data_assunzione.split("T")[0] : null,
                    data_colloquio_vol_faf: (response.data.data_colloquio_vol_faf) ? response.data.data_colloquio_vol_faf.split("T")[0] : null,
                    data_colloquio_socio: (response.data.data_colloquio_socio) ? response.data.data_colloquio_socio.split("T")[0] : null,
                    data_ospite: (response.data.data_ospite) ? response.data.data_ospite.split("T")[0] : null,
                    data_primo_contatto_vol: (response.data.data_primo_contatto_vol) ? response.data.data_primo_contatto_vol.split("T")[0] : null,
                });
            })
            .catch(e => {
                console.log(e);
            });
    };

    const saveStakeholder = () => {
        StakeholderService.create(
            {
                ...currentStakeholder,
                categoria: currentStakeholder.categoria?.join(', ')
            }
        )
            .then(response => {
                setCurrentStakeholder({
                    id: response.data.id,
                    titolo: response.data.titolo,
                    attacco_lettera: response.data.attacco_lettera,
                    ragione_sociale: response.data.ragione_sociale,
                    nome: response.data.nome,
                    cognome: response.data.cognome,
                    codice_fiscale: response.data.codice_fiscale,
                    partita_iva: response.data.partita_iva,
                    civico: response.data.civico,
                    indirizzo: response.data.indirizzo,
                    cap: response.data.cap,
                    citta: response.data.citta,
                    provincia: response.data.provincia,
                    telefono: response.data.telefono,
                    cellulare: response.data.cellulare,
                    email: response.data.email,
                    note: response.data.note,
                    data_nascita: response.data.data_nascita?.split("T")[0],
                    luogo_nascita: response.data.luogo_nascita,
                    sesso: response.data.sesso,
                    fonte: response.data.fonte,
                    data_inserimento: response.data.data_inserimento?.split("T")[0],
                    comunicazioni_email: response.data.comunicazioni_email,
                    comunicazioni_cartacee: response.data.comunicazioni_cartacee,
                    consenso_invio_materiale_informativo: response.data.consenso_invio_materiale_informativo,
                    consenso_ringraziamaneti: response.data.consenso_ringraziamaneti,
                    consenso_SMS: response.data.consenso_SMS,
                    consenso_contatto_telefonico: response.data.consenso_contatto_telefonico,
                    donatore: response.data.donatore,
                    categoria: response.data.categoria?.split(','),
                    tipo_donatore: response.data.tipo_donatore,
                    stato: response.data.stato,
                    data_decesso: response.data.data_decesso?.split("T")[0],
                    tessera: response.data.tessera,
                    pec: response.data.pec,
                    codice_sdi: response.data.codice_sdi,
                    donazioni_anno_corrente: response.data.donazioni_anno_corrente,
                    totale_donazioni: response.data.totale_donazioni,
                    n_donazioni: response.data.n_donazioni,
                    termometro_dimensione: response.data.termometro_dimensione,
                    termometro_fedelta: response.data.termometro_fedelta,
                    termometro_temperatura: response.data.termometro_temperatura,
                    data_prima_donazione: response.data.data_prima_donazione?.split("T")[0],
                    importo_prima_donazione: response.data.importo_prima_donazione,
                    mod_prima_donazione: response.data.mod_prima_donazione,
                    data_ultima_donazione: response.data.data_ultima_donazione?.split("T")[0],
                    importo_ultima_donazione: response.data.importo_ultima_donazione,
                    mesi_da_ultima_donazione: response.data.mesi_da_ultima_donazione,
                    utente_ultima_modifica: response.data.utente_ultima_modifica,
                    gdpr_2016_979_consenso_il: response.data.gdpr_2016_979_consenso_il?.split("T")[0],
                    gdpr_2016_979_negato_il: response.data.gdpr_2016_979_negato_il,
                    gdpr_2016_979_consenso: response.data.gdpr_2016_979_consenso,
                    dlgs_196_2003_consenso_il: response.data.dlgs_196_2003_consenso_il?.split("T")[0],
                    dlgs_196_2003_negato_il: response.data.dlgs_196_2003_negato_il,
                    dlgs_196_2003_consenso: response.data.dlgs_196_2003_consenso,
                    tipologia: response.data.tipologia,
                    titolo_studio: response.data.titolo_studio,
                    professione: response.data.professione,
                    stato_civile: response.data.stato_civile,
                    figli: response.data.figli,
                    corso_faf: response.data.corso_faf,
                    testo_corso_faf: response.data.testo_corso_faf,
                    corso_vol: response.data.corso_vol,
                    testo_corso_vol: response.data.testo_corso_vol,
                    data_recesso: response.data.data_recesso?.split("T")[0],
                    data_socio: response.data.data_socio?.split("T")[0],
                    tipologia_contratto: response.data.tipologia_contratto,
                    data_assunzione: response.data.data_assunzione?.split("T")[0],
                    data_colloquio_vol_faf: response.data.data_colloquio_vol_faf?.split("T")[0],
                    data_colloquio_socio: response.data.data_colloquio_socio?.split("T")[0],
                    data_ospite: response.data_ospite?.split("T")[0],
                    invalidita: response.invalidita,
                    perc_invalidita: response.perc_invalidita,
                    riconoscimento_handicap: response.riconoscimento_handicap,
                    iscrizione_liste_collocamento: response.iscrizione_liste_collocamento,
                    pensione: response.pensione,
                    data_primo_contatto_vol: response.data_primo_contatto_vol?.split("T")[0],
                    tipo_vol: response.tipo_vol,
                    disponibilita: response.disponibilita
                });
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (id) {
            getStakeholder(id);
        }
        retrieveTables();
    }, [id]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCurrentStakeholder({ ...currentStakeholder, [name]: value });
    };


    const [tables, setTables] = useState([]);

    const retrieveTables = () => {
        TableService.getAll()
            .then(response => {
                setTables(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const [catSelected, setCatSelected] = useState([]);
    const [tipoVol, setTipoVol] = useState([]);

    // selezione multipla con chip
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const theme = useTheme();

    function getStyles(name, catSelected, theme) {
        return {
            fontWeight:
                catSelected.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setCatSelected(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setCurrentStakeholder({ ...currentStakeholder, categoria: event.target.value });
    };


    const handleTipVolChange = (event) => {
        const {
            target: { value },
        } = event;
        setTipoVol(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setCurrentStakeholder({ ...currentStakeholder, tipo_vol: event.target.value });
    };

    // end chip select


    const updateStakeholder = () => {
        StakeholderService.update(currentStakeholder.id, {
            ...currentStakeholder,
            categoria: (currentStakeholder.categoria) ? currentStakeholder.categoria.toString() : null,
            tipo_vol: currentStakeholder.tipo_vol ? currentStakeholder.tipo_vol.toString() : null,
        })
            .then(response => {
                alert("Contatto aggiornato correttamente")
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deletestakeholder = () => {
        StakeholderService.remove(currentStakeholder.id)
            .then(response => {
                navigate("/stakeholders");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <TabContext value={(value) ? value : ((addAction) ? "4" : "1")} >
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" centered >
                    <Tab sx={{ backgroundColor: "#1976d2", color: "white" }} label={(currentStakeholder.cognome) ? currentStakeholder.nome + " " + currentStakeholder.cognome : currentStakeholder.ragione_sociale} />
                    <Tab label="Anagrafica" value="1" disabled={info} />
                    <Tab label="Info aggiuntive" value="3" disabled={info} />
                    <Tab label="Volontario/Familiare" value="6" disabled={info} />
                    {
                        (currentStakeholder.categoria) ?
                            currentStakeholder.categoria.includes("Socio Club") || currentStakeholder.categoria.includes("Ospite Club") ? (
                                <Tab label="Socio" value="7" disabled={info} />
                            ) : null : null

                    }
                    <Tab label="Donazioni" value="2" disabled={info} />
                    <Tab label="Azioni" value="4" />
                    <Tab label="Uscite" value="5" disabled={info} />
                    <Tab label="Consensi" value="8" disabled={info} />
                </TabList>

                <TabPanel value="1">
                    <TextField id="outlined-basic" label="Primo contatto" name="data_inserimento" type="date" InputLabelProps={{ shrink: true }} variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_inserimento} disabled={info} onChange={handleInputChange} />
                    <FormControl style={{ marginBottom: "20px", marginRight: "20px", minWidth: 120 }}>
                        <InputLabel id="demo-multiple-chip-label">Categoria</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            disabled={info}
                            value={(currentStakeholder.categoria) ? currentStakeholder.categoria : (catSelected ? catSelected : null)}
                            onChange={handleCategoryChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {tables.map((cat) =>
                                (cat.riferimento == 12) ? (
                                    <MenuItem
                                        key={cat.id}
                                        value={cat.campi}
                                        style={getStyles(cat, catSelected, theme)}
                                    >
                                        {cat.campi}
                                    </MenuItem>
                                ) : null)}
                        </Select>
                    </FormControl><br />
                    <FormControl sx={{ minWidth: "15ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Titolo</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(currentStakeholder.titolo) ? currentStakeholder.titolo : ""}
                            autowidth
                            disabled={info}
                            name="titolo"
                            onChange={handleInputChange}
                            label="Titolo"
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 9) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Nome" name="nome" variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.nome} InputLabelProps={{ shrink: true }} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Cognome" name="cognome" variant="outlined" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.cognome} InputLabelProps={{ shrink: true }} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Ragione Sociale" variant="outlined" name="ragione_sociale" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.ragione_sociale} InputLabelProps={{ shrink: true }} disabled={info} onChange={handleInputChange} />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px", minWidth: "10ch" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Sesso</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(currentStakeholder.sesso) ? currentStakeholder.sesso : ""}
                            autowidth
                            disabled={info}
                            name="sesso"
                            onChange={handleInputChange}
                            label="Sesso"
                        >
                            <MenuItem value={"F"}>F</MenuItem>
                            <MenuItem value={"M"}>M</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Data di nascita" variant="outlined" type="date" name="data_nascita" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.data_nascita} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Luogo di nascita" variant="outlined" name="luogo_nascita" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.luogo_nascita} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Codice fiscale" variant="outlined" name="codice_fiscale" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.codice_fiscale} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Telefono" variant="outlined" name="telefono" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.telefono} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Cellulare" variant="outlined" name="cellulare" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.cellulare} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" name="email" style={{ width: "35ch", marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.email} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Indirizzo" variant="outlined" name="indirizzo" style={{ marginBottom: "20px", marginRight: "20px", width: "50ch" }} value={currentStakeholder.indirizzo} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Civico" variant="outlined" name="civico" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.civico} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Città" variant="outlined" name="citta" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.citta} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Provincia" variant="outlined" name="provincia" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.provincia} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Cap" variant="outlined" name="cap" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.cap} disabled={info} InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
                </TabPanel>
                <TabPanel value="2">
                    {(currentStakeholder.id) ? <Donations sh={currentStakeholder} /> : null}
                </TabPanel>
                <TabPanel value="3">
                    <TextField id="outlined-basic" label="Società" variant="outlined" name="societa" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.societa} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Provenienza" variant="outlined" name="provenienza" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.provenienza} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Partita Iva" variant="outlined" name="partita_iva" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.partita_iva} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Legame" variant="outlined" name="legame" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.legame} disabled={info} onChange={handleInputChange} />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px", minWidth: "12ch" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(currentStakeholder.tipologia) ? currentStakeholder.tipologia : ""}
                            autowidth
                            disabled={info}
                            name="tipologia"
                            onChange={handleInputChange}
                            label="Tipologia"
                        >
                            <MenuItem value={"Niente"}>Niente</MenuItem>
                            <MenuItem value={"Direttamente"}>Direttamente</MenuItem>
                            <MenuItem value={"Familiare"}>Familiare</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: "15ch", marginBottom: "20px", marginRight: "20px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Fonte</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={(currentStakeholder.fonte) ? currentStakeholder.fonte : ""}
                            autowidth
                            disabled={info}
                            name="fonte"
                            onChange={handleInputChange}
                            label="Fonte"
                        >
                            {
                                tables.map((tab) =>
                                    (tab.riferimento == 11) ?
                                        <MenuItem value={tab.campi}>{tab.campi}</MenuItem>
                                        : null
                                )
                            }
                        </Select>
                    </FormControl>
                </TabPanel>
                <TabPanel value="4">
                    {(currentStakeholder.id) ? <Actions stakeholder={currentStakeholder}
                        dis={act} /> : null}
                </TabPanel>
                <TabPanel value="5" >
                    {(currentStakeholder.id) ? <Output sh={currentStakeholder} /> : null}
                </TabPanel>
                <TabPanel value="6" >
                    <TextField id="outlined-basic" label="Data primo contatto" variant="outlined" type="date" InputLabelProps={{ shrink: true }} name="data_primo_contatto_vol" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_primo_contatto_vol} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Data colloquio" variant="outlined" type="date" InputLabelProps={{ shrink: true }} name="data_colloquio_vol_faf" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_colloquio_vol_faf} disabled={info} onChange={handleInputChange} />
                    <FormControl style={{ marginBottom: "20px", marginRight: "20px", minWidth: "30ch" }}>
                        <InputLabel id="demo-multiple-chip-label">Tipologia volontario</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            disabled={info}
                            value={(currentStakeholder.tipo_vol) ? currentStakeholder.tipo_vol : (tipoVol ? tipoVol : null)}
                            onChange={handleTipVolChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {tables.map((cat) =>
                                (cat.riferimento == 10) ? (
                                    <MenuItem
                                        key={cat.id}
                                        value={cat.campi}
                                        style={getStyles(cat, tipoVol, theme)}
                                    >
                                        {cat.campi}
                                    </MenuItem>
                                ) : null)}
                        </Select>
                    </FormControl>
                    <TextField id="outlined-basic" label="Disponibilità" variant="outlined" InputLabelProps={{ shrink: true }} name="disponibilita" style={{ marginBottom: "20px", marginRight: "20px" }} value={(currentStakeholder.disponibilita)} disabled={info} onChange={handleInputChange} /><br />
                    <br />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Corso volontari</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={currentStakeholder.corso_vol}
                            disabled={info}
                            name="corso_vol"
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <TextField id="outlined-multiline-static" InputLabelProps={{ shrink: true }} label="Info corso volontari" variant="outlined" fullWidth multiline rows={4} name="testo_corso_vol" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.testo_corso_vol} disabled={info} onChange={handleInputChange} /><br />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Corso FAF</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            disabled={info}
                            defaultValue={currentStakeholder.corso_faf}
                            name="corso_faf"
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <TextField id="outlined-multiline-static" label="Info corso FAF" InputLabelProps={{ shrink: true }} variant="outlined" fullWidth multiline rows={4} name="testo_corso_faf" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.testo_corso_faf} disabled={info} onChange={handleInputChange} /><br />
                </TabPanel>
                <TabPanel value="7">
                    <TextField id="outlined-basic" label="Data colloquio" InputLabelProps={{ shrink: true }} variant="outlined" type="date" name="data_colloquio_socio" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_colloquio_socio} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Data ospite" InputLabelProps={{ shrink: true }} variant="outlined" type="date" name="data_ospite" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_ospite} disabled={info} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Data socio" InputLabelProps={{ shrink: true }} variant="outlined" type="date" name="data_socio" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.data_socio} disabled={info} onChange={handleInputChange} /><br />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Invalidità</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="invalidita"
                            disabled={info}
                            defaultValue={currentStakeholder.invalidita}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <TextField id="outlined-multiline-static" InputLabelProps={{ shrink: true }} label="% invalidità" variant="outlined" fullWidth multiline rows={4} name="perc_invalidita" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.perc_invalidita} disabled={info} onChange={handleInputChange} /><br />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Iscrizione collocamento</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={currentStakeholder.iscrizione_liste_collocamento}
                            name="iscrizione_liste_collocamento"
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-radio-buttons-group-label">Pensione</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={currentStakeholder.pensione}
                            name="pensione"
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </TabPanel>
                <TabPanel value="8">
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Comunicazioni email</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="comunicazioni_email"
                            onChange={handleInputChange}
                            value={currentStakeholder.comunicazioni_email}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Comunicazioni cartacee</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="comunicazioni_cartacee"
                            onChange={handleInputChange}
                            value={currentStakeholder.comunicazioni_cartacee}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Invio materiale informativo</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="consenso_invio_materiale_informativo"
                            onChange={handleInputChange}
                            value={currentStakeholder.consenso_invio_materiale_informativo}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Ringraziamenti</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="consenso_ringraziamaneti"
                            onChange={handleInputChange}
                            value={currentStakeholder.consenso_ringraziamaneti}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Invio SMS</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="consenso_SMS"
                            onChange={handleInputChange}
                            value={currentStakeholder.consenso_SMS}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Contatto telefonico</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="consenso_contatto_telefonico"
                            onChange={handleInputChange}
                            value={currentStakeholder.consenso_contatto_telefonico}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl><br />
                    <FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">GDPR 2016/679</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="gdpr_2016_979_consenso"
                            onChange={handleInputChange}
                            value={currentStakeholder.gdpr_2016_979_consenso}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <TextField id="outlined-basic" label="in data" variant="outlined" type="date" name="gdpr_2016_979_consenso_il" style={{ marginBottom: "20px", marginRight: "20px" }} value={currentStakeholder.gdpr_2016_979_consenso_il} InputLabelProps={{ shrink: true }} disabled={info} onChange={handleInputChange} />
                    <br /><FormControl sx={{ marginBottom: "20px", marginRight: "20px" }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">DLGS 196/2003</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="dlgs_196_2003_consenso"
                            onChange={handleInputChange}
                            value={currentStakeholder.dlgs_196_2003_consenso}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Si" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <TextField id="outlined-basic" label="in data" variant="outlined" type="date" name="dlgs_196_2003_consenso_il" style={{ marginBottom: "20px", marginRight: "20px" }} InputLabelProps={{ shrink: true }} value={currentStakeholder.dlgs_196_2003_consenso_il} disabled={info} onChange={handleInputChange} />
                </TabPanel>
                <Button variant="contained" sx={{ margin: "20px" }} onClick={(currentStakeholder.id) ? updateStakeholder : saveStakeholder}>{(currentStakeholder.id) ? "Aggiorna" : "Salva"}</Button>
            </Box>
        </TabContext >
    );
};

export default Stakeholder;