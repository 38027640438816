import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import clsx from 'clsx';

const columns = [
    { field: 'giorno', headerName: 'Giorno', width: 100, type: "date" },
    {
        field: 'nove', headerName: '9-10', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'dieci', headerName: '10-11', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'undici', headerName: '11-12', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'dodici', headerName: '12-13', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'tredici', headerName: '13-14', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'quattordici', headerName: '14-15', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'quindici', headerName: '15-16', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'sedici', headerName: '16-17', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
    {
        field: 'diciassette', headerName: '17-18', width: 130,
        cellClassName: (params) => {
            return clsx('super-app', { total: params.id == "totale" });
        }
    },
];


const buildRows = (inizio, fine, data) => {

    var days = [0];
    var day = new Date(inizio);
    day = new Date(day.setDate(day.getDate() - parseInt(1))).setHours(0, 0, 0, 0);
    var nextDay = new Date(day);
    var fine = new Date(fine).setHours(0,0,0,0);

    var newData = data.map((d) => {
        var giorni = [];
        for (var i = 0; (parseInt(d.orario_inizio?.split(":")[0]) + i) <= parseInt(d.orario_fine?.split(":")[0]); i++) {
            giorni.push(parseInt(d.orario_inizio?.split(":")[0]) + i);
        }
        return ({
            data: d.data,
            giorni: giorni
        })
    })

    for (var i = 0; nextDay < fine; i++) {
        days[i] = {
            id: i,
            giorno: new Date(nextDay.setDate(nextDay.getDate() + parseInt(1))),
            nove: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(9))).length,
            dieci: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(10))).length,
            undici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(11))).length,
            dodici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(12))).length,
            tredici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(13))).length,
            quattordici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(14))).length,
            quindici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(15))).length,
            sedici: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(16))).length,
            diciassette: (newData.filter(fil => ((new Date(new Date(fil.data).setHours(0, 0, 0, 0))).getTime() == nextDay.getTime()) && fil.giorni?.includes(17))).length,

        };
    }
    return days;
}

const countColumn = (rows, val) => {
    var sum = 0;
    rows.forEach(element =>
        sum += element[val]
    );
    return sum;
}


export default function DataTable(props) {

    var rows = [];
    props?.rows.length > 0 ? rows = buildRows(props.query.data_inizio, props.query.data_fine, props.rows)
        : rows = [{ id: 1 }]

    return (
        <>
            <Paper >
                <Box
                    sx={{
                        height: "auto",
                        width: '100%',
                        '& .super-app.total': {
                            backgroundColor: '#1976d214',
                            fontWeight: '600',
                        }
                    }}
                >
                    <DataGrid
                        rows={[...rows,
                        { id: 'totale', label: 'TOTALE', nove: countColumn(rows, "nove"), dieci: countColumn(rows, "dieci"), undici: countColumn(rows, "undici"), dodici: countColumn(rows, "dodici"), tredici: countColumn(rows, "tredici"), quattordici: countColumn(rows, "quattordici"), quindici: countColumn(rows, "quindici"), sedici: countColumn(rows, "sedici"), diciassette: countColumn(rows, "diciassette") }]}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        getRowId={(row) => row.id}
                        pageSizeOptions={[5, 10]}
                        autoHeight
                        slots={{ toolbar: GridToolbar }}
                    />
                </Box>
            </Paper>
        </>
    );
}