import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { Router, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


//service
import OutputService from "../services/OutputService";

// component
// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';


// Material Table
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import InfoIcon from '@mui/icons-material/Info';
import AddchartIcon from '@mui/icons-material/Addchart';
import OutputsService from "../services/OutputService";
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';



const OutputsList = () => {

    let navigate = useNavigate();

    const initialOutputState = {
        id: "",
        data_documento: "",
        data_pagamento: "",
        fattura_numero: "",
        importo: "",
        metodo: "",
        numero_registro: "",
        area_competenza: "",
        causale: "",
        campagna: "",
        descrizione: "",
        contatto_id: ""
    }

    const [Output, setOutput] = useState(initialOutputState);
    const [Outputs, setOutputs] = useState([]);

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        InfoIcon: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
        AddchartIcon: forwardRef((props, ref) => <AddchartIcon {...props} ref={ref} />)
    };

    useEffect(() => {
        retrieveOutputs();
    }, []);

    //start Dialog

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }


    const [open, setOpen] = React.useState(false);
    const [newOutput, setnewOutput] = useState(false);

    const handleClickOpen = (row) => {
        setOpen(true);
        setnewOutput(false);
        setOutput(row);
    };

    const handleNewOutput = () => {
        setOpen(true);
        setnewOutput(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialog = (e) => {
        setOpenDialog(true)

    }

    //end Dialog

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOutput({ ...Output, [name]: value });
    };

    const updateOutput = () => {
        OutputService.update(Output.id, Output)
            .then(response => {
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
        handleClose()
    };


    // new output
    const saveOutput = () => {
        var data = {
            id: Output.id,
            data_documento: Output.data_documento,
            data_pagamento: Output.data_pagamento,
            fattura_numero: Output.fattura_numero,
            importo: Output.importo,
            metodo: Output.metodo,
            numero_registro: Output.numero_registro,
            area_competenza: Output.area_competenza,
            causale: Output.causale,
            campagna: Output.campagna,
            descrizione: Output.descrizione,
            contatto_id: Output.contatto_id
        };

        OutputService.create(data)
            .then(response => {
                setOutput({
                    id: response.data.id,
                    data_documento: response.data.data_documento,
                    data_pagamento: response.data.data_pagamento,
                    fattura_numero: response.data.fattura_numero,
                    importo: response.data.importo,
                    metodo: response.data.metodo,
                    numero_registro: response.data.numero_registro,
                    area_competenza: response.data.area_competenza,
                    causale: response.data.causale,
                    campagna: response.data.campagna,
                    descrizione: response.data.descrizione,
                    contatto_id: response.data.contatto_id
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };
    // end output

    const retrieveOutputs = () => {
        OutputsService.getAll()
            .then(response => {
                setOutputs(response.data);
                console.log("uscite", response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {newOutput ? "Aggiungi uscita" : "Modifica uscita"}
                </DialogTitle>
                <DialogContent sx={{ marginTop: "10px" }}>
                    <TextField id="outlined-basic" label="Data documento" name="data_documento" InputLabelProps={{ shrink: true }} type="date" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.data_documento} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Data pagamento" name="data_pagamento" variant="outlined" InputLabelProps={{ shrink: true }} type="date" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.data_pagamento} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Numero fattura" name="fattura_numero" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.fattura_numero} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Importo" name="importo" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.importo} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Metodo" name="metodo" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.metodo} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Numero registro" name="numero_registro" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.numero_registro} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Area competenza" name="area_competenza" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.area_competenza} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Causale" name="causale" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.causale} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Campagna" name="campagna" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.campagna} onChange={handleInputChange} />
                    <TextField id="outlined-basic" label="Descrizione" name="descrizione" variant="outlined" style={{ marginTop: "10px", marginBottom: "20px", marginRight: "20px" }} value={Output.descrizione} onChange={handleInputChange} />
                    <DialogContentText>
                        Per salvare clicca su {newOutput ? "Salva" : "Aggiorna"} altrimenti Chiudi
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Chiudi
                    </Button>
                    <Button onClick={newOutput ? saveOutput : updateOutput}>{newOutput ? "Salva" : "Aggiorna"}</Button>
                </DialogActions>
            </Dialog>

            <Button variant="contained" sx={{ marginBottom: "20px" }} startIcon={<AddIcon />} onClick={handleNewOutput} >Aggiungi uscita</Button >
            <MaterialTable
                columns={[
                    { field: 'data_documento', width: 150, title: "Data documento", hidden: true },
                    { field: 'data_pagamento', width: 150, title: "Data pagamento", hidden: false },
                    { field: 'fattura_numero', width: 150, title: "Numero fattura", hidden: false },
                    { field: 'importo', width: 150, title: "Importo", hidden: true },
                    { field: 'metodo', width: 150, title: "Metodo", hidden: true, hiddenByColumnsButton: true },
                    { field: 'numero_registro', width: 150, title: "numero_registro", hidden: true, hiddenByColumnsButton: true },
                    { field: 'area_competenza', width: 150, title: "Area competenza", hidden: true, hiddenByColumnsButton: true },
                    { field: 'causale', width: 150, title: "Causale", hidden: true, hiddenByColumnsButton: true },
                    { field: 'campagna', width: 150, title: "Campagna", hidden: true, hiddenByColumnsButton: true },
                    { field: 'descrizione', width: 150, title: "Descrizione", hidden: true, hiddenByColumnsButton: true },
                ]}
                options={{
                    exportButton: true,
                    filtering: true,
                    filteringSelect: true,
                    columnsButton: true,
                    grouping: true,
                    paging: true,
                    pageSize: 20,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions: [5, 10, 20, 50],    // rows selection options
                    headerStyle: {
                        fontSize: 16,
                        fontWeight: "bold",
                    },
                }}
                localization={{
                    grouping: {
                        placeholder: 'Trascina qui un campo colonna'
                    },
                    pagination: {
                        labelDisplayedRows: '{from}-{to} di {count}',
                        labelRowsSelect: 'Righe',
                        firstAriaLabel: 'Prima pagina',
                        firstTooltip: 'Prima pagina',
                        previousAriaLabel: 'Pagina precedente',
                        previousTooltip: 'Pagina precedente',
                        nextAriaLabel: 'Pagina successiva',
                        nextTooltip: 'Pagina successiva',
                        lastAriaLabel: 'Ultima pagina',
                        lastTooltip: 'Ultima pagina'
                    },
                    toolbar: {
                        onRowsSelected: '{0} righe selezionate',
                        searchTooltip: 'Cerca',
                        searchPlaceholder: 'Cerca',
                        exportTitle: 'Esporta',
                        exportAriaLabel: 'Exporter',
                        showColumnsTitle: 'Mostra colonne',
                        showColumnsAriaLabel: 'Mostra colonne',
                        addRemoveColumns: 'Seleziona le colonne da visualizzare'
                    },
                    header: {
                        actions: 'Azioni'
                    },
                    body: {
                        emptyDataSourceMessage: 'Nessun contatto',
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                icons={tableIcons}
                data={Outputs}
                title="Uscite"
                actions={
                    [{
                        icon: tableIcons.Edit,
                        tooltip: 'Modifica',
                        onClick: (event, rowData) => {
                            handleClickOpen(rowData);
                        }
                    }]
                }
            />
        </>
    );
};

export default OutputsList;
