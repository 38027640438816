import http from "../http-common";

const getAll = () => {
    return http.get("/stakeholders");
};

const get = (id, v) => {
    return http.get(`/stakeholders/${id}/${v}`);
};


const create = data => {
    return http.post("/stakeholders", data);
};

const update = (id, data) => {
    return http.put(`/stakeholders/${id}`, data);
};

const remove = id => {
    return http.delete(`/stakeholders/${id}`);
};

const removeAll = () => {
    return http.delete(`/stakeholders`);
};

const findByTitle = title => {
    return http.get(`/stakeholders?title=${title}`);
};

const StakeholderService = {
    getAll,
    get,
    create,
    update,
    remove,
    removeAll,
    findByTitle
};

export default StakeholderService;
